'use strict';

import React, { Component } from 'react';
import { Switch, NavBar, Checkbox, Radio, Input, Tabs, TabBar, Badge } from 'antd-mobile';

import styles from './assets/index.module.css';

const print = function (value) {};
class DDS_lanhupage_0 extends Component {
  state = {};
  setValue(val) {}
  render() {
    return (
      <div className={`${styles.page} flex-col`}>
        <div className={`${styles['group_1']} flex-col`}>
          <div className={`${styles['block_1']} flex-row`}>
            <img
              className={`${styles['image_1']}`}
              src={
                require('./assets/img/SketchPng9f06d0db570db41de5c026ea08d5b9fd623f507cc4e13b72b7d9ec43ff08d10c.png')
              }
            />
            <span className={`${styles['text_1']}`}>首页</span>
            <span className={`${styles['text_2']}`}>公司简介</span>
            <span className={`${styles['text_3']}`}>主营业务</span>
            <span className={`${styles['text_4']}`}>产品方案</span>
            <span className={`${styles['text_5']}`}>合作伙伴</span>
            <span className={`${styles['text_6']}`}>营业网点</span>
            <span className={`${styles['text_7']}`}>&nbsp;人才招聘</span>
            <span className={`${styles['text_8']}`}>中/</span>
            <span className={`${styles['text_9']}`}>En</span>
            <img
              className={`${styles['label_1']}`}
              src={
                require('./assets/img/SketchPng891cf14de262b84ebfcf52ec76b81e4dfc293a2ca6a043b18cda43ce54425544.png')
              }
            />
            <span className={`${styles['text_10']}`}>400-880-5656</span>
          </div>
          <span className={`${styles['text_11']}`}>共创双赢</span>
        </div>
        <div className={`${styles['group_2']} flex-col`}>
          <div className={`${styles['block_2']} flex-row`}>
            <img
              className={`${styles['thumbnail_1']}`}
              src={
                require('./assets/img/SketchPng8d9fd0b4b7e251e0b23f0fd481f881efbbeb385e56b980b22a7ec4e60cf456dc.png')
              }
            />
            <span className={`${styles['text_12']}`}>金融</span>
            <div className={`${styles['box_1']} flex-col`} />
          </div>
          <div className={`${styles['block_3']} flex-row`}>
            <div className={`${styles['image-wrapper_1']} flex-col`}>
              <img
                className={`${styles['image_2']}`}
                src={
                  require('./assets/img/SketchPngf92d0fac75ce3b56e4e5612194e3da583f3adade0ab100b9bf641e28c8070356.png')
                }
              />
            </div>
            <div className={`${styles['image-wrapper_2']} flex-col`}>
              <img
                className={`${styles['image_3']}`}
                src={
                  require('./assets/img/SketchPng48bb5cefd91dca383cb1c50f7ba32b0d85c2d3b38484aa37b43312f016c49f7f.png')
                }
              />
            </div>
            <div className={`${styles['image-wrapper_3']} flex-col`}>
              <img
                className={`${styles['image_4']}`}
                src={
                  require('./assets/img/SketchPngbcefaec6e534adb4f02a008ad0a44f7dd981db140a32561d345ba0534ac42766.png')
                }
              />
            </div>
            <div className={`${styles['image-wrapper_4']} flex-col`}>
              <img
                className={`${styles['image_5']}`}
                src={
                  require('./assets/img/SketchPng9841d0b8d1233c8e591a55754ba1b34579fec772c2c9bd9b2c62a0ad6bebc145.png')
                }
              />
            </div>
          </div>
          <div className={`${styles['block_4']} flex-row`}>
            <div className={`${styles['image-wrapper_5']} flex-col`}>
              <img
                className={`${styles['image_6']}`}
                src={
                  require('./assets/img/SketchPng7723d63f9227ebfff9411b388f0ed5d2f514642b2361a513db49dd0cd908877d.png')
                }
              />
            </div>
            <div className={`${styles['image-wrapper_6']} flex-col`}>
              <img
                className={`${styles['image_7']}`}
                src={
                  require('./assets/img/SketchPng5083bae8e039e4a2adaf242fabc0927f2e0e270e00c86e1430b441d0c44afdae.png')
                }
              />
            </div>
            <div className={`${styles['image-wrapper_7']} flex-col`}>
              <img
                className={`${styles['image_8']}`}
                src={
                  require('./assets/img/SketchPng950d4c21a78cbbea30958c61ea90597e73fe1f0672e4326ca6cd85fa02f4f37d.png')
                }
              />
            </div>
            <div className={`${styles['image-wrapper_8']} flex-col`}>
              <img
                className={`${styles['image_9']}`}
                src={
                  require('./assets/img/SketchPng795f2bb5dfb5a9c32f3d4c0205aacf68ab1b0d5ec92d93a238b012d3d66bc1c4.png')
                }
              />
            </div>
            <div className={`${styles['image-wrapper_9']} flex-col`}>
              <img
                className={`${styles['label_2']}`}
                src={
                  require('./assets/img/SketchPng4865c29fdca52372ca94024eca78ded09ad4c881953456b8ac06dbd13605e750.png')
                }
              />
            </div>
          </div>
          <div className={`${styles['block_5']} flex-row`}>
            <div className={`${styles['box_2']} flex-row`}>
              <div className={`${styles['image-text_1']} flex-row justify-between`}>
                <img
                  className={`${styles['image_10']}`}
                  src={
                    require('./assets/img/SketchPng9d58d8f429f1007c2f529b42b5dfe3e6912f568e24e15c3dd4dcc8536b6ca192.png')
                  }
                />
                <span className={`${styles['text-group_1']}`}>
                  申美信息拥有较强的开发能力和丰富经验的专业化队伍。和他们合作的这几年，给公司节约了开发成本，交付迅速高效。是值得信赖的伙伴。
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;——金融公司
                </span>
              </div>
            </div>
            <div className={`${styles['box_3']} flex-row`}>
              <div className={`${styles['image-text_2']} flex-row justify-between`}>
                <img
                  className={`${styles['image_11']}`}
                  src={
                    require('./assets/img/SketchPng9d58d8f429f1007c2f529b42b5dfe3e6912f568e24e15c3dd4dcc8536b6ca192.png')
                  }
                />
                <span className={`${styles['text-group_2']}`}>
                  集团是很早就与申美信息合作的企业，多年来的合作成果颇丰，未来还将继续深入合作。
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;——&nbsp;集团公司
                </span>
              </div>
            </div>
            <div className={`${styles['box_4']} flex-col justify-between`}>
              <div className={`${styles['image-wrapper_10']} flex-col`}>
                <img
                  className={`${styles['label_3']}`}
                  src={
                    require('./assets/img/SketchPng3cf5aed6be634876fa86d0106a713af639ea10ac425b846b3cad51f4294ea66a.png')
                  }
                />
              </div>
              <div className={`${styles['box_5']} flex-col`}>
                <div className={`${styles['image-wrapper_11']} flex-col`}>
                  <img
                    className={`${styles['image_12']}`}
                    src={
                      require('./assets/img/SketchPng6723eac3ac83c63bda95850749a39a9cc8fd5ab06b563ab15cda1dad0e971329.png')
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={`${styles['block_6']} flex-row`}>
            <div className={`${styles['image-text_3']} flex-row justify-between`}>
              <img
                className={`${styles['thumbnail_2']}`}
                src={
                  require('./assets/img/SketchPng8d9fd0b4b7e251e0b23f0fd481f881efbbeb385e56b980b22a7ec4e60cf456dc.png')
                }
              />
              <span className={`${styles['text-group_3']}`}>互联网</span>
            </div>
            <div className={`${styles['box_6']} flex-col`} />
          </div>
          <div className={`${styles['block_7']} flex-row`}>
            <div className={`${styles['image-wrapper_12']} flex-col`}>
              <img
                className={`${styles['image_13']}`}
                src={
                  require('./assets/img/SketchPng51ac87840ffe5aaff9438289da7e8fca8def634bb25da8456f96f2b288e69c7b.png')
                }
              />
            </div>
            <div className={`${styles['image-wrapper_13']} flex-col`}>
              <img
                className={`${styles['image_14']}`}
                src={
                  require('./assets/img/SketchPnge22bc45fc383cc3b8ea59b071ad87e2527dc457d27fec29c8037eadf69ce9a7f.png')
                }
              />
            </div>
            <div className={`${styles['image-wrapper_14']} flex-col`}>
              <img
                className={`${styles['image_15']}`}
                src={
                  require('./assets/img/SketchPng2a9d0de64f48930d8ae00cec4de43bfb9917f010ddda89895b8f1982653bcae8.png')
                }
              />
            </div>
          </div>
          <div className={`${styles['block_8']} flex-row`}>
            <div className={`${styles['image-text_4']} flex-row justify-between`}>
              <img
                className={`${styles['image_16']}`}
                src={
                  require('./assets/img/SketchPng9d58d8f429f1007c2f529b42b5dfe3e6912f568e24e15c3dd4dcc8536b6ca192.png')
                }
              />
              <span className={`${styles['text-group_4']}`}>
                申美信息拥有较强的开发能力和丰富经验的专业化队伍。和他们合作的这几年，给公司节约了开发成本，交付迅速高效。是值得信赖的伙伴。
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;——金融公司
              </span>
            </div>
          </div>
          <div className={`${styles['block_9']} flex-row`}>
            <div className={`${styles['image-text_5']} flex-row justify-between`}>
              <img
                className={`${styles['thumbnail_3']}`}
                src={
                  require('./assets/img/SketchPng8d9fd0b4b7e251e0b23f0fd481f881efbbeb385e56b980b22a7ec4e60cf456dc.png')
                }
              />
              <span className={`${styles['text-group_5']}`}>通信</span>
            </div>
            <div className={`${styles['group_3']} flex-col`} />
          </div>
          <div className={`${styles['block_10']} flex-row`}>
            <div className={`${styles['image-wrapper_15']} flex-col`}>
              <img
                className={`${styles['image_17']}`}
                src={
                  require('./assets/img/SketchPng51ac87840ffe5aaff9438289da7e8fca8def634bb25da8456f96f2b288e69c7b.png')
                }
              />
            </div>
            <div className={`${styles['image-wrapper_16']} flex-col`}>
              <img
                className={`${styles['image_18']}`}
                src={
                  require('./assets/img/SketchPnge22bc45fc383cc3b8ea59b071ad87e2527dc457d27fec29c8037eadf69ce9a7f.png')
                }
              />
            </div>
            <div className={`${styles['image-wrapper_17']} flex-col`}>
              <img
                className={`${styles['image_19']}`}
                src={
                  require('./assets/img/SketchPng2a9d0de64f48930d8ae00cec4de43bfb9917f010ddda89895b8f1982653bcae8.png')
                }
              />
            </div>
            <div className={`${styles['image-wrapper_18']} flex-col`}>
              <img
                className={`${styles['label_4']}`}
                src={
                  require('./assets/img/SketchPng1f85afdf0b802e5ce41c23fcd4b6f06d29a34be7bb4aeb83fd0db3bdfa4c61d8.png')
                }
              />
            </div>
          </div>
          <div className={`${styles['block_11']} flex-row justify-between`}>
            <div className={`${styles['box_7']} flex-row`}>
              <div className={`${styles['image-text_6']} flex-row justify-between`}>
                <img
                  className={`${styles['image_20']}`}
                  src={
                    require('./assets/img/SketchPng9d58d8f429f1007c2f529b42b5dfe3e6912f568e24e15c3dd4dcc8536b6ca192.png')
                  }
                />
                <span className={`${styles['text-group_6']}`}>
                  申美信息拥有较强的开发能力和丰富经验的专业化队伍。和他们合作的这几年，给公司节约了开发成本，交付迅速高效。是值得信赖的伙伴。
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;——金融公司
                </span>
              </div>
            </div>
            <div className={`${styles['box_8']} flex-row`}>
              <div className={`${styles['image-text_7']} flex-row justify-between`}>
                <img
                  className={`${styles['image_21']}`}
                  src={
                    require('./assets/img/SketchPng9d58d8f429f1007c2f529b42b5dfe3e6912f568e24e15c3dd4dcc8536b6ca192.png')
                  }
                />
                <span className={`${styles['text-group_7']}`}>
                  上银集团是很早就与申美信息合作的企业，多年来的合作成果颇丰，未来还将继续深入合作。
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;——&nbsp;上海银行
                </span>
              </div>
            </div>
          </div>
          <div className={`${styles['block_12']} flex-row`}>
            <div className={`${styles['image-text_8']} flex-row justify-between`}>
              <img
                className={`${styles['thumbnail_4']}`}
                src={
                  require('./assets/img/SketchPng8d9fd0b4b7e251e0b23f0fd481f881efbbeb385e56b980b22a7ec4e60cf456dc.png')
                }
              />
              <span className={`${styles['text-group_8']}`}>制造业</span>
            </div>
            <div className={`${styles['group_4']} flex-col`} />
          </div>
          <div className={`${styles['block_13']} flex-row`}>
            <div className={`${styles['image-wrapper_19']} flex-col`}>
              <img
                className={`${styles['image_22']}`}
                src={
                  require('./assets/img/SketchPng51ac87840ffe5aaff9438289da7e8fca8def634bb25da8456f96f2b288e69c7b.png')
                }
              />
            </div>
            <div className={`${styles['image-wrapper_20']} flex-col`}>
              <img
                className={`${styles['image_23']}`}
                src={
                  require('./assets/img/SketchPnge22bc45fc383cc3b8ea59b071ad87e2527dc457d27fec29c8037eadf69ce9a7f.png')
                }
              />
            </div>
            <div className={`${styles['image-wrapper_21']} flex-col`}>
              <img
                className={`${styles['image_24']}`}
                src={
                  require('./assets/img/SketchPng2a9d0de64f48930d8ae00cec4de43bfb9917f010ddda89895b8f1982653bcae8.png')
                }
              />
            </div>
            <div className={`${styles['image-wrapper_22']} flex-col`}>
              <img
                className={`${styles['label_5']}`}
                src={
                  require('./assets/img/SketchPng1f85afdf0b802e5ce41c23fcd4b6f06d29a34be7bb4aeb83fd0db3bdfa4c61d8.png')
                }
              />
            </div>
          </div>
          <div className={`${styles['block_14']} flex-row justify-between`}>
            <div className={`${styles['group_5']} flex-row`}>
              <div className={`${styles['image-text_9']} flex-row justify-between`}>
                <img
                  className={`${styles['image_25']}`}
                  src={
                    require('./assets/img/SketchPng9d58d8f429f1007c2f529b42b5dfe3e6912f568e24e15c3dd4dcc8536b6ca192.png')
                  }
                />
                <span className={`${styles['text-group_9']}`}>
                  申美信息拥有较强的开发能力和丰富经验的专业化队伍。和他们合作的这几年，给公司节约了开发成本，交付迅速高效。是值得信赖的伙伴。
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;——金融公司
                </span>
              </div>
            </div>
            <div className={`${styles['group_6']} flex-row`}>
              <div className={`${styles['image-text_10']} flex-row justify-between`}>
                <img
                  className={`${styles['image_26']}`}
                  src={
                    require('./assets/img/SketchPng9d58d8f429f1007c2f529b42b5dfe3e6912f568e24e15c3dd4dcc8536b6ca192.png')
                  }
                />
                <span className={`${styles['text-group_10']}`}>
                  集团是很早就与申美信息合作的企业，多年来的合作成果颇丰，未来还将继续深入合作。
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;——&nbsp;集团公司
                </span>
              </div>
            </div>
          </div>
          <div className={`${styles['block_15']} flex-row`}>
            <div className={`${styles['image-text_11']} flex-row justify-between`}>
              <img
                className={`${styles['thumbnail_5']}`}
                src={
                  require('./assets/img/SketchPng8d9fd0b4b7e251e0b23f0fd481f881efbbeb385e56b980b22a7ec4e60cf456dc.png')
                }
              />
              <span className={`${styles['text-group_11']}`}>服务业</span>
            </div>
            <div className={`${styles['group_7']} flex-col`} />
          </div>
          <div className={`${styles['block_16']} flex-row`}>
            <div className={`${styles['image-wrapper_23']} flex-col`}>
              <img
                className={`${styles['image_27']}`}
                src={
                  require('./assets/img/SketchPng51ac87840ffe5aaff9438289da7e8fca8def634bb25da8456f96f2b288e69c7b.png')
                }
              />
            </div>
            <div className={`${styles['image-wrapper_24']} flex-col`}>
              <img
                className={`${styles['image_28']}`}
                src={
                  require('./assets/img/SketchPnge22bc45fc383cc3b8ea59b071ad87e2527dc457d27fec29c8037eadf69ce9a7f.png')
                }
              />
            </div>
            <div className={`${styles['image-wrapper_25']} flex-col`}>
              <img
                className={`${styles['image_29']}`}
                src={
                  require('./assets/img/SketchPng2a9d0de64f48930d8ae00cec4de43bfb9917f010ddda89895b8f1982653bcae8.png')
                }
              />
            </div>
            <div className={`${styles['image-wrapper_26']} flex-col`}>
              <img
                className={`${styles['label_6']}`}
                src={
                  require('./assets/img/SketchPng1f85afdf0b802e5ce41c23fcd4b6f06d29a34be7bb4aeb83fd0db3bdfa4c61d8.png')
                }
              />
            </div>
          </div>
          <div className={`${styles['block_17']} flex-row`}>
            <div className={`${styles['image-text_12']} flex-row justify-between`}>
              <img
                className={`${styles['image_30']}`}
                src={
                  require('./assets/img/SketchPng9d58d8f429f1007c2f529b42b5dfe3e6912f568e24e15c3dd4dcc8536b6ca192.png')
                }
              />
              <span className={`${styles['text-group_12']}`}>
                申美信息拥有较强的开发能力和丰富经验的专业化队伍。和他们合作的这几年，给公司节约了开发成本，交付迅速高效。是值得信赖的伙伴。
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;——平安金融
              </span>
            </div>
          </div>
          <div className={`${styles['block_18']} flex-row`}>
            <div className={`${styles['image-text_13']} flex-row justify-between`}>
              <img
                className={`${styles['thumbnail_6']}`}
                src={
                  require('./assets/img/SketchPng8d9fd0b4b7e251e0b23f0fd481f881efbbeb385e56b980b22a7ec4e60cf456dc.png')
                }
              />
              <span className={`${styles['text-group_13']}`}>政企</span>
            </div>
            <div className={`${styles['section_1']} flex-col`} />
          </div>
          <div className={`${styles['block_19']} flex-row`}>
            <div className={`${styles['image-wrapper_27']} flex-col`}>
              <img
                className={`${styles['image_31']}`}
                src={
                  require('./assets/img/SketchPng51ac87840ffe5aaff9438289da7e8fca8def634bb25da8456f96f2b288e69c7b.png')
                }
              />
            </div>
            <div className={`${styles['image-wrapper_28']} flex-col`}>
              <img
                className={`${styles['image_32']}`}
                src={
                  require('./assets/img/SketchPnge22bc45fc383cc3b8ea59b071ad87e2527dc457d27fec29c8037eadf69ce9a7f.png')
                }
              />
            </div>
            <div className={`${styles['image-wrapper_29']} flex-col`}>
              <img
                className={`${styles['image_33']}`}
                src={
                  require('./assets/img/SketchPng2a9d0de64f48930d8ae00cec4de43bfb9917f010ddda89895b8f1982653bcae8.png')
                }
              />
            </div>
            <div className={`${styles['image-wrapper_30']} flex-col`}>
              <img
                className={`${styles['label_7']}`}
                src={
                  require('./assets/img/SketchPng1f85afdf0b802e5ce41c23fcd4b6f06d29a34be7bb4aeb83fd0db3bdfa4c61d8.png')
                }
              />
            </div>
          </div>
          <div className={`${styles['block_20']} flex-row justify-between`}>
            <div className={`${styles['group_8']} flex-row`}>
              <div className={`${styles['image-text_14']} flex-row justify-between`}>
                <img
                  className={`${styles['image_34']}`}
                  src={
                    require('./assets/img/SketchPng9d58d8f429f1007c2f529b42b5dfe3e6912f568e24e15c3dd4dcc8536b6ca192.png')
                  }
                />
                <span className={`${styles['text-group_14']}`}>
                  申美信息拥有较强的开发能力和丰富经验的专业化队伍。和他们合作的这几年，给公司节约了开发成本，交付迅速高效。是值得信赖的伙伴。
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;——金融公司
                </span>
              </div>
            </div>
            <div className={`${styles['group_9']} flex-row`}>
              <div className={`${styles['image-text_15']} flex-row justify-between`}>
                <img
                  className={`${styles['image_35']}`}
                  src={
                    require('./assets/img/SketchPng9d58d8f429f1007c2f529b42b5dfe3e6912f568e24e15c3dd4dcc8536b6ca192.png')
                  }
                />
                <span className={`${styles['text-group_15']}`}>
                  集团是很早就与申美信息合作的企业，多年来的合作成果颇丰，未来还将继续深入合作。
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;——&nbsp;集团公司
                </span>
              </div>
            </div>
          </div>
          <div className={`${styles['block_21']} flex-row`}>
            <div className={`${styles['image-text_16']} flex-row justify-between`}>
              <img
                className={`${styles['thumbnail_7']}`}
                src={
                  require('./assets/img/SketchPng8d9fd0b4b7e251e0b23f0fd481f881efbbeb385e56b980b22a7ec4e60cf456dc.png')
                }
              />
              <span className={`${styles['text-group_16']}`}>其他</span>
            </div>
            <div className={`${styles['group_10']} flex-col`} />
          </div>
          <div className={`${styles['block_22']} flex-row`}>
            <div className={`${styles['image-wrapper_31']} flex-col`}>
              <img
                className={`${styles['image_36']}`}
                src={
                  require('./assets/img/SketchPng51ac87840ffe5aaff9438289da7e8fca8def634bb25da8456f96f2b288e69c7b.png')
                }
              />
            </div>
            <div className={`${styles['image-wrapper_32']} flex-col`}>
              <img
                className={`${styles['image_37']}`}
                src={
                  require('./assets/img/SketchPnge22bc45fc383cc3b8ea59b071ad87e2527dc457d27fec29c8037eadf69ce9a7f.png')
                }
              />
            </div>
            <div className={`${styles['image-wrapper_33']} flex-col`}>
              <img
                className={`${styles['image_38']}`}
                src={
                  require('./assets/img/SketchPng2a9d0de64f48930d8ae00cec4de43bfb9917f010ddda89895b8f1982653bcae8.png')
                }
              />
            </div>
            <div className={`${styles['image-wrapper_34']} flex-col`}>
              <img
                className={`${styles['label_8']}`}
                src={
                  require('./assets/img/SketchPng1f85afdf0b802e5ce41c23fcd4b6f06d29a34be7bb4aeb83fd0db3bdfa4c61d8.png')
                }
              />
            </div>
          </div>
          <div className={`${styles['block_23']} flex-col`}>
            <div className={`${styles['group_11']} flex-row`}>
              {/* <div className={`${styles['block_24']} flex-col justify-between`}>
                <span className={`${styles['text_13']}`}>IT综合服务</span>
                <div className={`${styles['text-group_17']} flex-col justify-between`}>
                  <span className={`${styles['text_14']}`}>ITO人力</span>
                  <span className={`${styles['text_15']}`}>ITO项目</span>
                  <span className={`${styles['text_16']}`}>ITO测试</span>
                </div>
              </div>
              <div className={`${styles['block_25']} flex-col justify-between`}>
                <span className={`${styles['text_17']}`}>企业服务</span>
                <div className={`${styles['text-group_18']} flex-col justify-between`}>
                  <span className={`${styles['text_18']}`}>BPO服务</span>
                  <span className={`${styles['text_19']}`}>NDC&amp;ODC</span>
                  <span className={`${styles['text_20']}`}>知识产权服务</span>
                </div>
              </div>
              <div className={`${styles['block_26']} flex-col justify-between`}>
                <span className={`${styles['text_21']}`}>关于申美</span>
                <div className={`${styles['text-group_19']} flex-col justify-between`}>
                  <span className={`${styles['text_22']}`}>企业简介</span>
                  <span className={`${styles['text_23']}`}>招聘中心</span>
                  <span className={`${styles['text_24']}`}>合作伙伴</span>
                </div>
              </div> */}
              <div className={`${styles['block_27']} flex-col justify-between`}>
                <div className={`${styles['image-text_17']} flex-col justify-between`}>
                  <img
                    className={`${styles['image_39']}`}
                    src={
                      require('./assets/img/SketchPngc6cbbd9032162aa64557e58a63aa4ca3f06762f357621999bb52d7507235eed5.png')
                    }
                  />
                  <span className={`${styles['text-group_20']}`}>上海申美信息科技有限公司</span>
                </div>
                <span className={`${styles['paragraph_1']}`}>
                  地址：上海市普陀区大渡河路388弄5号楼406室
                  <br />
                  电话：13391356946
                  <br />
                  email：surmeta&#64;surmeta.com
                </span>
              </div>
            </div>
            <div className={`${styles['group_12']} flex-row justify-between`}>
              <div className={`${styles['image-text_18']} flex-col justify-between`}>
                <div className={`${styles['box_9']} flex-col`} />
                <span className={`${styles['text-group_21']}`}>官方微信号</span>
              </div>
              <div className={`${styles['image-text_19']} flex-col justify-between`}>
                <div className={`${styles['group_13']} flex-col`} />
                <span className={`${styles['text-group_22']}`}>抖音视频号</span>
              </div>
            </div>
          </div>
        </div>
        <div className={`${styles['group_14']} flex-col`}>
          <div className={`${styles['text-wrapper_1']}`}>
            <span className={`${styles['paragraph_2']}`}>
              沪ICP证0876125号&nbsp;&nbsp;&nbsp;沪ICP备987788号&nbsp;
              <br />
              copyright©
            </span>
            <span className={`${styles['text_25']}`}>SurMeta&nbsp;</span>
            <span className={`${styles['text_26']}`}>法律声明</span>
          </div>
        </div>
      </div>
    );
  }
}
export default DDS_lanhupage_0;
