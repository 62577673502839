'use strict';

import React, { Component } from 'react';
import {Nav} from '../component/nav';
import {Footer} from '../component/footer';

import './about.css';


class about extends Component {

  state = {mapState:'zg'}

  render() {
    return (
      <div className='page-main'>
        <Nav curr="公司简介"></Nav>
        <div className='app-about-banner'>
            <div className='app-about-banner-title'>专业的数智化服务商</div>
        </div>

        <div className='app-about-main'>
            <div className='app-about-main-title'>公司简介</div>
        </div>
        <div style={{width:'100%',textAlign:'center',marginBottom:30}}>
              <img 
                referrerPolicy="no-referrer" 
                src={
                    require('./img/jiantou.png')
                    }
                ></img>
        </div>
        <div className='app-about-main-content'>
                        <div className='app-about-main-dec'>
                            公司立足上海、服务全国，为金融、汽车、互联网、通讯、数字科技、制造、零售、房地产、医疗、教育、交通、政务、航空、物流、能源、环保等行业客户，提供数智化产品和科技服务，使客户聚焦自身核心主业，更专注与高效实现商业成功。
                        </div>
        </div>

        <div className='app-about-culture'>
                <div className='app-about-culture-title'>
                    {'>  企业文化'}
                </div>
                <div className='app-flex'>
                    <div className='app-about-culture-item'>
                        <div className='app-about-culture-item-img'>
                              <img 
                                referrerPolicy="no-referrer" 
                                src={
                                    require('./img/yuanjign.png')
                                    }
                                ></img>
                        </div>
                        <div className='app-about-culture-item-title'> 愿景</div>
                         <div className='app-about-culture-item-dec'>
                            成为一家客户满意、员工幸福的数智化服务长青科技企业。
                        </div>
                    </div>
                    <div className='app-about-culture-item'>
                        <div className='app-about-culture-item-img'>
                              <img 
                                referrerPolicy="no-referrer" 
                                src={
                                    require('./img/shiming.png')
                                    }
                                ></img>
                        </div>
                        <div className='app-about-culture-item-title'>使命</div>
                         <div className='app-about-culture-item-dec'>
                            以创新的科技与服务，让社会更高效与美好。
                        </div>
                    </div>
                    <div className='app-about-culture-item'>
                        <div className='app-about-culture-item-img'>
                              <img 
                                referrerPolicy="no-referrer" 
                                src={
                                    require('./img/jzg.png')
                                    }
                                ></img>
                        </div>
                        <div className='app-about-culture-item-title'> 价值观</div>
                         <div className='app-about-culture-item-dec'>
                            客户为本、价值为纲、正念正行、务实创新、同创共享、福报社会。
                        </div>
                    </div>

                </div>    
        </div>
        
        <Footer/>
 
       </div>
    )
  }
}
export default about;
