'use strict';

import React, { Component } from 'react';
import {Nav} from '../component/nav';
import {Footer} from '../component/footer';

import './business.css';


class Business extends Component {

  state = {mapState:'zg'}

  render() {
    return (
      <div className='page-main'>
        <Nav curr="主营业务"></Nav>
       
         <div className='app-business-banner'>
            <div className='app-business-banner-t1'>
                使客户聚焦自身核心主业
            </div>
            <div className='app-business-banner-t2'>
                更专注与高效实现商业成功
            </div>
             {/* <div className='app-flex'>
                <div className='app-business-nav'>
                    <div>信息技术外包</div>
                    <div>信息技术生态业务</div>
                    <div>知识流程外包</div>
                     <div>人力资源外包</div>
                     <div>商务流程外包</div>
                </div>
            </div> */}
        </div>

        <div className='app-flex app-business-title' style={{marginTop:30}}>服务概况</div>
        <div style={{width:'100%',textAlign:'center',marginBottom:30,marginTop:10}}>
                    <img 
                        referrerPolicy="no-referrer" 
                        src={
                            require('./img/jiantou.png')
                            }
                        ></img>
        </div>

        <div className='app-flex'>
            <div style={{width:880,textAlign:'left',lineHeight:'24px'}}>
                &nbsp;&nbsp;&nbsp;&nbsp;金融行业正在经历快速增长，而且随着外界环境的改变，也在持续不断的发生变化和变革。为了更好的应对变革，金融机构需要重新审视当前的战略，重新评价系统和流程中的商业模式，以保持活力和竞争力。
                <br/>
                &nbsp;&nbsp;&nbsp;&nbsp;申美信息以深厚的行业知识，为客户提供多样化的专业服务，帮助客户改善与用户之间的互动与交流，加强工作流程管理和商务智能，并简化传统应用以支持实时、灵活的业务流程，享受效率、收益和创新。同时，使客户能更好的管理风险，提高产品的性能和快速实现业务转型。
            </div>
        </div>

        <BusinessServerTitle title='信息技术外包服务(ITO)'/>

        <div className='app-flex'>
            <div className='app-business-item-bg' data-type='ito'>
                 {'>'} 人力外包服务              
            </div>
        </div>
        <BusinessServerItem items={['开发','测试','前端']} />
        <BusinessServerItem items={['前端','运维','设计']} />
        <BusinessServerItem items={['商务','管理','...']} />

          <div className='app-flex'>
            <div className='app-business-item-bg' data-type='xmwb'>
                 {'>'} 项目外包服务              
            </div>
        </div>
        <BusinessServerItem items={['开发','测试','运维']} />
        <BusinessServerItem items={['集成','实施','综合']} />

          <div className='app-flex'>
            <div className='app-business-item-bg' data-type='yffw'>
                 {'>'} 研发中心服务              
            </div>
        </div>
       <BusinessServerItem items={['驻场','近岸','离岸']} />


        <BusinessServerTitle title='信息技术生态业务(ITEB) '/>
        <BusinessServerItem items={['产品代理','产品实施','解决方案']} />
        <BusinessServerItem items={['培训认证','...','blank']} />


        <BusinessServerTitle title='知识流程外包服务(KPO)'/>
        <BusinessServerItem items={['知识管理','战略咨询','技术咨询']} />

        <BusinessServerTitle title='企业综合服务(ECS) '/>
        <BusinessServerItem items={['市场推广','知识产权','展会峰会']} />
        <BusinessServerItem items={['产业协同','...','blank']} />

        <BusinessServerTitle title='人事外包服务(HRO)'/>
        <BusinessServerItem items={['猎头','招聘','代发薪']} />
        <BusinessServerItem items={['人事代理','人才测评','区域人才调研']} />
        

        <BusinessServerTitle title='商务流程外包服务(BPO)'/>
        <BusinessServerItem items={['内容服务','基础建设','技术运营']} />
        <BusinessServerItem items={['数据服务','人力资源外包','客服中心']} />

     
       <div style={{width:'100%',height:60}}></div>
     
       
        <Footer/>
 
       </div>
    )
  }
}


const BusinessServerTitle = (props) =>{
    return (<div style={{width:'100%'}}>
    <div className='app-flex app-business-title' style={{marginTop:30}}>{props.title}</div>
        <div style={{width:'100%',textAlign:'center',marginBottom:30,marginTop:10}}>
                    <img
                        referrerPolicy="no-referrer" 
                        src={
                            require('./img/jiantou.png')
                            }
                        ></img>
        </div></div>)
}


const BusinessServerItem = (props) =>{
    return (<div className='app-flex' >
                {
                    props.items.map((d,i)=>{

                        let isRight = (i != 2);
                        let s = (d=='blank'?'':d);
                        return  <div key={i} className='app-business-item' data-right={isRight} data-type={d}>{s}</div>  
                    })
                }
        </div>)
}


export default Business;
