'use strict';

import React, { Component } from 'react';
import { Switch, NavBar, Checkbox, Radio, Input, Tabs, TabBar, Badge } from 'antd-mobile';

import styles from './assets/index.module.css';

const print = function (value) {};
class DDS_lanhupage_0 extends Component {
  state = {};
  setValue(val) {}
  render() {
    return (
      <div className={`${styles.page} flex-col`}>
        <div className={`${styles['box_1']} flex-col`}>
          <div className={`${styles['group_1']} flex-row`}>
            <img
              className={`${styles['image_1']}`}
              src={
                require('./assets/img/SketchPng9f06d0db570db41de5c026ea08d5b9fd623f507cc4e13b72b7d9ec43ff08d10c.png')
              }
            />
            <span className={`${styles['text_1']}`}>首页</span>
            <span className={`${styles['text_2']}`}>公司简介</span>
            <span className={`${styles['text_3']}`}>主营业务</span>
            <span className={`${styles['text_4']}`}>产品方案</span>
            <span className={`${styles['text_5']}`}>合作伙伴</span>
            <span className={`${styles['text_6']}`}>营业网点</span>
            <span className={`${styles['text_7']}`}>&nbsp;人才招聘</span>
            <span className={`${styles['text_8']}`}>中/</span>
            <span className={`${styles['text_9']}`}>En</span>
            <img
              className={`${styles['label_1']}`}
              src={
                require('./assets/img/SketchPng891cf14de262b84ebfcf52ec76b81e4dfc293a2ca6a043b18cda43ce54425544.png')
              }
            />
            <span className={`${styles['text_10']}`}>400-880-5656</span>
          </div>
          <span className={`${styles['paragraph_1']}`}>
            使客户聚焦自身核心主业
            <br />
            更专注与高效实现商业成功
          </span>
          <div className={`${styles['group_2']} flex-col justify-end`}>
            <div className={`${styles['text-wrapper_1']} flex-row`}>
              <span className={`${styles['text_11']}`}>金融</span>
              <span className={`${styles['text_12']}`}>互联网</span>
              <span className={`${styles['text_13']}`}>通信</span>
              <span className={`${styles['text_14']}`}>制造</span>
              <span className={`${styles['text_15']}`}>服务</span>
              <span className={`${styles['text_16']}`}>政企</span>
              <span className={`${styles['text_17']}`}>其他</span>
            </div>
            <div className={`${styles['block_1']} flex-row`}>
              <div className={`${styles['section_1']} flex-col`} />
            </div>
          </div>
        </div>
        <div className={`${styles['box_2']} flex-col`}>
          <img
            className={`${styles['image_2']}`}
            src={
              require('./assets/img/SketchPnge5bede092dfa68525abcc96b679d45ea12b7556f0c4486cfc18e01d11dea3f7c.png')
            }
          />
          <div className={`${styles['box_3']} flex-row justify-between`}>
            <img
              className={`${styles['thumbnail_1']}`}
              src={
                require('./assets/img/SketchPng8d9fd0b4b7e251e0b23f0fd481f881efbbeb385e56b980b22a7ec4e60cf456dc.png')
              }
            />
            <span className={`${styles['text_18']}`}>热门行业解决方案</span>
          </div>
          <div className={`${styles['box_4']} flex-row justify-between`}>
            <div className={`${styles['group_3']} flex-row`}>
              <div className={`${styles['image-text_1']} flex-col justify-between`}>
                <div className={`${styles['box_5']} flex-col`}>
                  <div className={`${styles['group_4']} flex-row`}>
                    <span className={`${styles['text_19']}`}>银行</span>
                    <div className={`${styles['text-wrapper_2']} flex-col`}>
                      <span className={`${styles['text_20']}`}>热门</span>
                    </div>
                    <div className={`${styles['section_2']} flex-row`}>
                      <div className={`${styles['image-text_2']} flex-row justify-between`}>
                        <span className={`${styles['text-group_1']}`}>了解详情</span>
                        <img
                          className={`${styles['thumbnail_2']}`}
                          src={
                            require('./assets/img/SketchPnga5e41a414111d264a82e6cd746a628edf276d84e095508c09d222915dc9f40ae.png')
                          }
                        />
                      </div>
                      <div className={`${styles['image-text_3']} flex-row justify-between`}>
                        <span className={`${styles['text-group_1']}`}>了解详情</span>
                        <img
                          className={`${styles['thumbnail_2']}`}
                          src={
                            require('./assets/img/SketchPnga5e41a414111d264a82e6cd746a628edf276d84e095508c09d222915dc9f40ae.png')
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <span className={`${styles['text-group_2']}`}>帮助银行客户在数字化时代保持领先，调升客户满意度和服务效率</span>
              </div>
            </div>
            <div className={`${styles['group_5']} flex-row`}>
              <div className={`${styles['image-text_4']} flex-col justify-between`}>
                <div className={`${styles['section_3']} flex-col`}>
                  <div className={`${styles['box_6']} flex-row`}>
                    <span className={`${styles['text_21']}`}>保险</span>
                    <div className={`${styles['text-wrapper_3']} flex-col`}>
                      <span className={`${styles['text_22']}`}>热门</span>
                    </div>
                    <div className={`${styles['box_7']} flex-row`}>
                      <div className={`${styles['image-text_5']} flex-row justify-between`}>
                        <span className={`${styles['text-group_3']}`}>了解详情</span>
                        <img
                          className={`${styles['thumbnail_3']}`}
                          src={
                            require('./assets/img/SketchPnga5e41a414111d264a82e6cd746a628edf276d84e095508c09d222915dc9f40ae.png')
                          }
                        />
                      </div>
                      <div className={`${styles['image-text_6']} flex-row justify-between`}>
                        <span className={`${styles['text-group_3']}`}>了解详情</span>
                        <img
                          className={`${styles['thumbnail_3']}`}
                          src={
                            require('./assets/img/SketchPnga5e41a414111d264a82e6cd746a628edf276d84e095508c09d222915dc9f40ae.png')
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <span className={`${styles['text-group_4']}`}>帮助保险客户在数字化时代保持领先，调升客户满意度和服务效率</span>
              </div>
            </div>
            <div className={`${styles['group_6']} flex-row`}>
              <div className={`${styles['image-text_7']} flex-col justify-between`}>
                <div className={`${styles['box_8']} flex-col`}>
                  <div className={`${styles['block_2']} flex-row`}>
                    <span className={`${styles['text_23']}`}>汽车</span>
                    <div className={`${styles['text-wrapper_4']} flex-col`}>
                      <span className={`${styles['text_24']}`}>热门</span>
                    </div>
                    <div className={`${styles['group_7']} flex-row`}>
                      <div className={`${styles['image-text_8']} flex-row justify-between`}>
                        <span className={`${styles['text-group_5']}`}>了解详情</span>
                        <img
                          className={`${styles['thumbnail_4']}`}
                          src={
                            require('./assets/img/SketchPnga5e41a414111d264a82e6cd746a628edf276d84e095508c09d222915dc9f40ae.png')
                          }
                        />
                      </div>
                      <div className={`${styles['image-text_9']} flex-row justify-between`}>
                        <span className={`${styles['text-group_5']}`}>了解详情</span>
                        <img
                          className={`${styles['thumbnail_4']}`}
                          src={
                            require('./assets/img/SketchPnga5e41a414111d264a82e6cd746a628edf276d84e095508c09d222915dc9f40ae.png')
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <span className={`${styles['text-group_6']}`}>帮助车企客户在数字化时代保持领先，调升客户满意度和服务效率</span>
              </div>
            </div>
          </div>
          <span className={`${styles['text_25']}`}>细分领域解决方案</span>
          <img
            className={`${styles['image_3']}`}
            src={
              require('./assets/img/SketchPngb7565c2a0bf16563288b784db875592136146c31ac32867ed742c2e769bc921a.png')
            }
          />
          <div className={`${styles['box_9']} flex-row`}>
            <div className={`${styles['image-text_10']} flex-row justify-between`}>
              <img
                className={`${styles['thumbnail_5']}`}
                src={
                  require('./assets/img/SketchPng8d9fd0b4b7e251e0b23f0fd481f881efbbeb385e56b980b22a7ec4e60cf456dc.png')
                }
              />
              <span className={`${styles['text-group_7']}`}>金融</span>
            </div>
            <div className={`${styles['block_3']} flex-col`} />
          </div>
          <div className={`${styles['box_10']} flex-row justify-between`}>
            <div className={`${styles['group_8']} flex-col`}>
              <div className={`${styles['group_9']} flex-row`}>
                <div className={`${styles['image-text_11']} flex-row justify-between`}>
                  <img
                    className={`${styles['label_2']}`}
                    src={
                      require('./assets/img/SketchPng1909e5b1ae7ca306873a858431f4ced45b075db0872a7711db62d1bdd4d37d9e.png')
                    }
                  />
                  <span className={`${styles['text-group_8']}`}>泛金融</span>
                </div>
                <div className={`${styles['text-wrapper_5']} flex-col`}>
                  <span className={`${styles['text_26']}`}>热门</span>
                </div>
                <img
                  className={`${styles['thumbnail_6']}`}
                  src={
                    require('./assets/img/SketchPng9fc23872803bc1f577f64d43d9e5a24f6c38055a544230505950571bc5aedb1b.png')
                  }
                />
              </div>
              <span className={`${styles['text_27']}`}>赋能金融行业数字化转型，助力客户业务创新和发展</span>
            </div>
            <div className={`${styles['group_10']} flex-col`}>
              <div className={`${styles['box_11']} flex-row`}>
                <div className={`${styles['image-text_12']} flex-row justify-between`}>
                  <img
                    className={`${styles['label_3']}`}
                    src={
                      require('./assets/img/SketchPng43743c351d241171f767951c8ce3e1c4b8ab2bc08c7ad0e48c6bbdbf6f4d1bd3.png')
                    }
                  />
                  <span className={`${styles['text-group_9']}`}>银行</span>
                </div>
                <div className={`${styles['text-wrapper_6']} flex-col`}>
                  <span className={`${styles['text_28']}`}>热门</span>
                </div>
                <img
                  className={`${styles['thumbnail_7']}`}
                  src={
                    require('./assets/img/SketchPng9fc23872803bc1f577f64d43d9e5a24f6c38055a544230505950571bc5aedb1b.png')
                  }
                />
              </div>
              <div className={`${styles['text-wrapper_7']} flex-row`}>
                <span className={`${styles['text_29']}`}>帮助银行客户在数字化时代保持领先</span>
              </div>
            </div>
            <div className={`${styles['group_11']} flex-col`}>
              <div className={`${styles['group_12']} flex-row`}>
                <div className={`${styles['image-text_13']} flex-row justify-between`}>
                  <img
                    className={`${styles['label_4']}`}
                    src={
                      require('./assets/img/SketchPng06ac217010bbadd1543e913f616edf626ef36678438dc3c9f8c99aa95e9b4221.png')
                    }
                  />
                  <span className={`${styles['text-group_10']}`}>证券</span>
                </div>
                <div className={`${styles['text-wrapper_8']} flex-col`}>
                  <span className={`${styles['text_30']}`}>热门</span>
                </div>
                <img
                  className={`${styles['thumbnail_8']}`}
                  src={
                    require('./assets/img/SketchPng9fc23872803bc1f577f64d43d9e5a24f6c38055a544230505950571bc5aedb1b.png')
                  }
                />
              </div>
              <span className={`${styles['paragraph_2']}`}>
                助力客户在满足监管合规的前提下实现决策智能与降本增效
                <br />
                <br />
              </span>
            </div>
          </div>
          <div className={`${styles['box_12']} flex-row`}>
            <div className={`${styles['section_4']} flex-col`}>
              <div className={`${styles['box_13']} flex-row`}>
                <div className={`${styles['image-text_14']} flex-row justify-between`}>
                  <img
                    className={`${styles['label_5']}`}
                    src={
                      require('./assets/img/SketchPng46c3564e437ecf07d4b221b299b9e5355cade188af236f1aa520c11360c58d4b.png')
                    }
                  />
                  <span className={`${styles['text-group_11']}`}>保险</span>
                </div>
                <div className={`${styles['text-wrapper_9']} flex-col`}>
                  <span className={`${styles['text_31']}`}>热门</span>
                </div>
                <img
                  className={`${styles['thumbnail_9']}`}
                  src={
                    require('./assets/img/SketchPng9fc23872803bc1f577f64d43d9e5a24f6c38055a544230505950571bc5aedb1b.png')
                  }
                />
              </div>
              <span className={`${styles['text_32']}`}>赋能金融行业数字化转型，助力客户业务创新和发展</span>
            </div>
            <div className={`${styles['section_5']} flex-col`}>
              <div className={`${styles['image-text_15']} flex-row justify-between`}>
                <img
                  className={`${styles['label_6']}`}
                  src={
                    require('./assets/img/SketchPng76bbf236716e23df2a50e39b2aa2b4f4f43ba82e5e6152ad9fdf6ecda84ca935.png')
                  }
                />
                <span className={`${styles['text-group_12']}`}>互金</span>
              </div>
              <span className={`${styles['text_33']}`}>帮助银行客户在数字化时代保持领先</span>
            </div>
            <div className={`${styles['image-wrapper_1']} flex-col`}>
              <img
                className={`${styles['label_7']}`}
                src={
                  require('./assets/img/SketchPng4865c29fdca52372ca94024eca78ded09ad4c881953456b8ac06dbd13605e750.png')
                }
              />
            </div>
          </div>
          <div className={`${styles['box_14']} flex-row justify-between`}>
            <div className={`${styles['block_4']} flex-col justify-between`}>
              <div className={`${styles['box_15']} flex-row`}>
                <div className={`${styles['image-text_16']} flex-row justify-between`}>
                  <img
                    className={`${styles['thumbnail_10']}`}
                    src={
                      require('./assets/img/SketchPng8d9fd0b4b7e251e0b23f0fd481f881efbbeb385e56b980b22a7ec4e60cf456dc.png')
                    }
                  />
                  <span className={`${styles['text-group_13']}`}>互联网</span>
                </div>
                <div className={`${styles['section_6']} flex-col`} />
              </div>
              <div className={`${styles['box_16']} flex-row justify-between`}>
                <div className={`${styles['group_13']} flex-col`}>
                  <div className={`${styles['box_17']} flex-row`}>
                    <div className={`${styles['image-text_17']} flex-row justify-between`}>
                      <img
                        className={`${styles['label_8']}`}
                        src={
                          require('./assets/img/SketchPng7752b71f4a7f770d4928d556f9dabf69d251c3b38414d05fcee40fc2b1216b8d.png')
                        }
                      />
                      <span className={`${styles['text-group_14']}`}>新零售</span>
                    </div>
                    <div className={`${styles['text-wrapper_10']} flex-col`}>
                      <span className={`${styles['text_34']}`}>热门</span>
                    </div>
                    <img
                      className={`${styles['thumbnail_11']}`}
                      src={
                        require('./assets/img/SketchPng9fc23872803bc1f577f64d43d9e5a24f6c38055a544230505950571bc5aedb1b.png')
                      }
                    />
                  </div>
                  <div className={`${styles['text-wrapper_11']} flex-row`}>
                    <span className={`${styles['text_35']}`}>互联网零售新浪潮，助力企业出海</span>
                  </div>
                </div>
                <div className={`${styles['group_14']} flex-row`}>
                  <div className={`${styles['image-text_18']} flex-row justify-between`}>
                    <img
                      className={`${styles['label_9']}`}
                      src={
                        require('./assets/img/SketchPng896dcbcaee31d30a01d8d95ca11ed9bb0d822397615c1151f245944416c38538.png')
                      }
                    />
                    <div className={`${styles['text-group_15']} flex-col justify-between`}>
                      <span className={`${styles['text_36']}`}>智慧医疗</span>
                      <span className={`${styles['text_37']}`}>帮助银行客户在数字化时代保持领先</span>
                    </div>
                  </div>
                </div>
                <div className={`${styles['group_15']} flex-row`}>
                  <div className={`${styles['image-text_19']} flex-row justify-between`}>
                    <img
                      className={`${styles['label_10']}`}
                      src={
                        require('./assets/img/SketchPng594367a3baa4985ad0dc95a376b79a4f62128601cb7f2c3629bf0121e363cb89.png')
                      }
                    />
                    <div className={`${styles['text-group_16']} flex-col justify-between`}>
                      <span className={`${styles['text_38']}`}>智慧农业</span>
                      <span className={`${styles['paragraph_3']}`}>
                        助力客户在满足监管合规的前提下实现决策智能与降本增效
                        <br />
                        <br />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={`${styles['block_5']} flex-col justify-between`}>
              <div className={`${styles['image-wrapper_2']} flex-col`}>
                <img
                  className={`${styles['label_11']}`}
                  src={
                    require('./assets/img/SketchPng3cf5aed6be634876fa86d0106a713af639ea10ac425b846b3cad51f4294ea66a.png')
                  }
                />
              </div>
              <div className={`${styles['group_16']} flex-col`}>
                <div className={`${styles['image-wrapper_3']} flex-col`}>
                  <img
                    className={`${styles['image_4']}`}
                    src={
                      require('./assets/img/SketchPng6723eac3ac83c63bda95850749a39a9cc8fd5ab06b563ab15cda1dad0e971329.png')
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={`${styles['box_18']} flex-row justify-between`}>
            <div className={`${styles['block_6']} flex-row`}>
              <div className={`${styles['image-text_20']} flex-row justify-between`}>
                <img
                  className={`${styles['label_12']}`}
                  src={
                    require('./assets/img/SketchPng8a7ad47c87756d0db278f47d0077abe84b5fea19d6d5d7ca055c8a0c6ec960e2.png')
                  }
                />
                <div className={`${styles['text-group_17']} flex-col justify-between`}>
                  <span className={`${styles['text_39']}`}>智慧出行</span>
                  <span className={`${styles['text_40']}`}>互联网零售新浪潮，助力企业出海</span>
                </div>
              </div>
            </div>
            <div className={`${styles['block_7']} flex-row`}>
              <div className={`${styles['image-text_21']} flex-row justify-between`}>
                <img
                  className={`${styles['label_13']}`}
                  src={
                    require('./assets/img/SketchPnge82ffe5f2c18cdc412bd7a2d50145a7e5938054de70bf64bf47cea852aa7cf99.png')
                  }
                />
                <div className={`${styles['text-group_18']} flex-col justify-between`}>
                  <span className={`${styles['text_41']}`}>智慧公园</span>
                  <span className={`${styles['text_42']}`}>帮助银行客户在数字化时代保持领先</span>
                </div>
              </div>
            </div>
            <div className={`${styles['block_8']} flex-row`}>
              <div className={`${styles['image-text_22']} flex-row justify-between`}>
                <img
                  className={`${styles['label_14']}`}
                  src={
                    require('./assets/img/SketchPng14c2902ffe90f8fa7f0e79a1a36b251c554d54e2390c739fb8826cb11234e7c6.png')
                  }
                />
                <div className={`${styles['text-group_19']} flex-col justify-between`}>
                  <span className={`${styles['text_43']}`}>智慧教育</span>
                  <span className={`${styles['paragraph_4']}`}>
                    助力客户在满足监管合规的前提下实现决策智能与降本增效
                    <br />
                    <br />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className={`${styles['box_19']} flex-row justify-between`}>
            <div className={`${styles['box_20']} flex-row`}>
              <div className={`${styles['image-text_23']} flex-row justify-between`}>
                <img
                  className={`${styles['label_15']}`}
                  src={
                    require('./assets/img/SketchPngb8d2dc26642c364dc94ecb0bef6e2b3cdde64069a5fdd0f09840c8422065b3de.png')
                  }
                />
                <div className={`${styles['text-group_20']} flex-col justify-between`}>
                  <span className={`${styles['text_44']}`}>行业门户</span>
                  <span className={`${styles['text_45']}`}>互联网零售新浪潮，助力企业出海</span>
                </div>
              </div>
            </div>
            <div className={`${styles['box_21']} flex-row`}>
              <div className={`${styles['image-text_24']} flex-row justify-between`}>
                <img
                  className={`${styles['label_16']}`}
                  src={
                    require('./assets/img/SketchPng705138c6152bdafd7311bb9bee5619153bff62a037a07d7ead52218bce5bb9e6.png')
                  }
                />
                <div className={`${styles['text-group_21']} flex-col justify-between`}>
                  <span className={`${styles['text_46']}`}>论坛博客</span>
                  <span className={`${styles['text_47']}`}>帮助银行客户在数字化时代保持领先</span>
                </div>
              </div>
            </div>
            <div className={`${styles['box_22']} flex-col`}>
              <div className={`${styles['section_7']} flex-row justify-between`}>
                <div className={`${styles['image-text_25']} flex-row justify-between`}>
                  <img
                    className={`${styles['label_17']}`}
                    src={
                      require('./assets/img/SketchPng7c252a613c0b28245eaa5ba7d5c8d0a80c727a4e741cf4d05e409891a84f6249.png')
                    }
                  />
                  <span className={`${styles['text-group_22']}`}>直播视频</span>
                </div>
                <div className={`${styles['text-wrapper_12']} flex-col`}>
                  <span className={`${styles['text_48']}`}>热门</span>
                </div>
              </div>
              <span className={`${styles['paragraph_5']}`}>
                助力客户在满足监管合规的前提下实现决策智能与降本增效
                <br />
                <br />
              </span>
            </div>
          </div>
          <div className={`${styles['box_23']} flex-row`}>
            <div className={`${styles['image-text_26']} flex-row justify-between`}>
              <img
                className={`${styles['thumbnail_12']}`}
                src={
                  require('./assets/img/SketchPng8d9fd0b4b7e251e0b23f0fd481f881efbbeb385e56b980b22a7ec4e60cf456dc.png')
                }
              />
              <span className={`${styles['text-group_23']}`}>通信</span>
            </div>
            <div className={`${styles['group_17']} flex-col`} />
          </div>
          <div className={`${styles['box_24']} flex-col`}>
            <div className={`${styles['section_8']} flex-row`}>
              <div className={`${styles['image-text_27']} flex-row justify-between`}>
                <img
                  className={`${styles['label_18']}`}
                  src={
                    require('./assets/img/SketchPng95f5bb70cfa69ba22cf839b98574029dfc670379135167454755873154f40520.png')
                  }
                />
                <span className={`${styles['text-group_24']}`}>5G/高速网</span>
              </div>
              <div className={`${styles['text-wrapper_13']} flex-col`}>
                <span className={`${styles['text_49']}`}>热门</span>
              </div>
              <img
                className={`${styles['thumbnail_13']}`}
                src={
                  require('./assets/img/SketchPng9fc23872803bc1f577f64d43d9e5a24f6c38055a544230505950571bc5aedb1b.png')
                }
              />
            </div>
            <span className={`${styles['paragraph_6']}`}>
              运用大数据技术和挖掘模型，助力客户洞察先机，开发新的业务潜能…
              <br />
              <br />
            </span>
          </div>
          <div className={`${styles['box_25']} flex-row`}>
            <div className={`${styles['image-text_28']} flex-row justify-between`}>
              <img
                className={`${styles['thumbnail_14']}`}
                src={
                  require('./assets/img/SketchPng8d9fd0b4b7e251e0b23f0fd481f881efbbeb385e56b980b22a7ec4e60cf456dc.png')
                }
              />
              <span className={`${styles['text-group_25']}`}>制造业</span>
            </div>
            <div className={`${styles['section_9']} flex-col`} />
          </div>
          <div className={`${styles['box_26']} flex-row justify-between`}>
            <div className={`${styles['box_27']} flex-col`}>
              <div className={`${styles['block_9']} flex-row`}>
                <div className={`${styles['image-text_29']} flex-row justify-between`}>
                  <img
                    className={`${styles['label_19']}`}
                    src={
                      require('./assets/img/SketchPng77e7c079df55a91df16d7d228ca6ccf7ce60486c26d548130f39a48d5fa8e2ca.png')
                    }
                  />
                  <span className={`${styles['text-group_26']}`}>汽车业</span>
                </div>
                <div className={`${styles['text-wrapper_14']} flex-col`}>
                  <span className={`${styles['text_50']}`}>热门</span>
                </div>
                <img
                  className={`${styles['thumbnail_15']}`}
                  src={
                    require('./assets/img/SketchPng9fc23872803bc1f577f64d43d9e5a24f6c38055a544230505950571bc5aedb1b.png')
                  }
                />
              </div>
              <span className={`${styles['text_51']}`}>以客户为中心，助力汽车行业的智能化和网联化…</span>
            </div>
            <div className={`${styles['box_28']} flex-col`}>
              <div className={`${styles['section_10']} flex-row`}>
                <div className={`${styles['image-text_30']} flex-row justify-between`}>
                  <img
                    className={`${styles['label_20']}`}
                    src={
                      require('./assets/img/SketchPng6209dfd6fadc3c2006bc82d89d1852ab8acf98ec551e9708ab0250c3b7d94221.png')
                    }
                  />
                  <span className={`${styles['text-group_27']}`}>工业4.0</span>
                </div>
                <div className={`${styles['text-wrapper_15']} flex-col`}>
                  <span className={`${styles['text_52']}`}>热门</span>
                </div>
                <img
                  className={`${styles['thumbnail_16']}`}
                  src={
                    require('./assets/img/SketchPng9fc23872803bc1f577f64d43d9e5a24f6c38055a544230505950571bc5aedb1b.png')
                  }
                />
              </div>
              <span className={`${styles['text_53']}`}>为制造业实现柔性生产，按需定制，智能化工厂</span>
            </div>
          </div>
          <div className={`${styles['box_29']} flex-row`}>
            <div className={`${styles['image-text_31']} flex-row justify-between`}>
              <img
                className={`${styles['thumbnail_17']}`}
                src={
                  require('./assets/img/SketchPng8d9fd0b4b7e251e0b23f0fd481f881efbbeb385e56b980b22a7ec4e60cf456dc.png')
                }
              />
              <span className={`${styles['text-group_28']}`}>服务业</span>
            </div>
            <div className={`${styles['block_10']} flex-col`} />
          </div>
          <div className={`${styles['box_30']} flex-row justify-between`}>
            <div className={`${styles['block_11']} flex-col`}>
              <div className={`${styles['box_31']} flex-row`}>
                <div className={`${styles['image-text_32']} flex-row justify-between`}>
                  <img
                    className={`${styles['label_21']}`}
                    src={
                      require('./assets/img/SketchPngae730a40726a82c02377c34a633da50d108db115050a70c9e5e93b252f57c454.png')
                    }
                  />
                  <span className={`${styles['text-group_29']}`}>服务咨询</span>
                </div>
                <div className={`${styles['text-wrapper_16']} flex-col`}>
                  <span className={`${styles['text_54']}`}>热门</span>
                </div>
                <img
                  className={`${styles['thumbnail_18']}`}
                  src={
                    require('./assets/img/SketchPng9fc23872803bc1f577f64d43d9e5a24f6c38055a544230505950571bc5aedb1b.png')
                  }
                />
              </div>
              <span className={`${styles['text_55']}`}>数字化提升服务业运营效率与客户满意度</span>
            </div>
            <div className={`${styles['block_12']} flex-col`}>
              <div className={`${styles['group_18']} flex-row`}>
                <div className={`${styles['image-text_33']} flex-row justify-between`}>
                  <img
                    className={`${styles['label_22']}`}
                    src={
                      require('./assets/img/SketchPngdb985a31fa789f139faf313ae821df4b1b74463f20aaa33286929af724bd6a16.png')
                    }
                  />
                  <span className={`${styles['text-group_30']}`}>电商</span>
                </div>
                <div className={`${styles['text-wrapper_17']} flex-col`}>
                  <span className={`${styles['text_56']}`}>热门</span>
                </div>
                <img
                  className={`${styles['thumbnail_19']}`}
                  src={
                    require('./assets/img/SketchPng9fc23872803bc1f577f64d43d9e5a24f6c38055a544230505950571bc5aedb1b.png')
                  }
                />
              </div>
              <div className={`${styles['text-wrapper_18']} flex-row`}>
                <span className={`${styles['text_57']}`}>助力电商企业全模式服务全球市场</span>
              </div>
            </div>
          </div>
          <div className={`${styles['box_32']} flex-row`}>
            <div className={`${styles['image-text_34']} flex-row justify-between`}>
              <img
                className={`${styles['thumbnail_20']}`}
                src={
                  require('./assets/img/SketchPng8d9fd0b4b7e251e0b23f0fd481f881efbbeb385e56b980b22a7ec4e60cf456dc.png')
                }
              />
              <span className={`${styles['text-group_31']}`}>政企</span>
            </div>
            <div className={`${styles['group_19']} flex-col`} />
          </div>
          <div className={`${styles['box_33']} flex-row justify-between`}>
            <div className={`${styles['block_13']} flex-col`}>
              <div className={`${styles['section_11']} flex-row`}>
                <div className={`${styles['image-text_35']} flex-row justify-between`}>
                  <img
                    className={`${styles['label_23']}`}
                    src={
                      require('./assets/img/SketchPng892f92d170f4ff807b8f43d871d1133240dbcac9c80e2ad24f32a2e12615d8c5.png')
                    }
                  />
                  <span className={`${styles['text-group_32']}`}>智慧城市</span>
                </div>
                <div className={`${styles['text-wrapper_19']} flex-col`}>
                  <span className={`${styles['text_58']}`}>热门</span>
                </div>
                <img
                  className={`${styles['thumbnail_21']}`}
                  src={
                    require('./assets/img/SketchPng9fc23872803bc1f577f64d43d9e5a24f6c38055a544230505950571bc5aedb1b.png')
                  }
                />
              </div>
              <span className={`${styles['paragraph_7']}`}>
                数字重构,&nbsp;合作共建,&nbsp;赋能智能城市新生态
                <br />
                <br />
              </span>
            </div>
            <div className={`${styles['block_14']} flex-row`}>
              <div className={`${styles['image-text_36']} flex-row justify-between`}>
                <img
                  className={`${styles['label_24']}`}
                  src={
                    require('./assets/img/SketchPngfda7925373a643d4674d9f4ffd5c6c78f4266b033e50db410cc7992195e8b3bb.png')
                  }
                />
                <div className={`${styles['text-group_33']} flex-col justify-between`}>
                  <span className={`${styles['text_59']}`}>智慧安防</span>
                  <span className={`${styles['text_60']}`}>结合人脸识别技术，安防管理数字化、集成化、智能化。</span>
                </div>
              </div>
            </div>
            <div className={`${styles['block_15']} flex-row`}>
              <div className={`${styles['image-text_37']} flex-row justify-between`}>
                <img
                  className={`${styles['label_25']}`}
                  src={
                    require('./assets/img/SketchPnge1440c6e8ac2b1dd5337d2da44d172273129a832855eb4e13454d09a2bcef364.png')
                  }
                />
                <div className={`${styles['text-group_34']} flex-col justify-between`}>
                  <span className={`${styles['text_61']}`}>智慧政务</span>
                  <span className={`${styles['text_62']}`}>在线政务公开、办理</span>
                </div>
              </div>
            </div>
          </div>
          <div className={`${styles['box_34']} flex-row`}>
            <div className={`${styles['image-text_38']} flex-row justify-between`}>
              <img
                className={`${styles['thumbnail_22']}`}
                src={
                  require('./assets/img/SketchPng8d9fd0b4b7e251e0b23f0fd481f881efbbeb385e56b980b22a7ec4e60cf456dc.png')
                }
              />
              <span className={`${styles['text-group_35']}`}>其他</span>
            </div>
            <div className={`${styles['box_35']} flex-col`} />
          </div>
          <div className={`${styles['box_36']} flex-row justify-between`}>
            <div className={`${styles['box_37']} flex-col`}>
              <div className={`${styles['box_38']} flex-row justify-between`}>
                <div className={`${styles['image-text_39']} flex-row justify-between`}>
                  <img
                    className={`${styles['label_26']}`}
                    src={
                      require('./assets/img/SketchPngb62cbd14f651c181524507ce661c3528a2b67b9bfae561fb378905b9ba4a089b.png')
                    }
                  />
                  <span className={`${styles['text-group_36']}`}>ICT软硬一体综合解决方案</span>
                </div>
                <img
                  className={`${styles['thumbnail_23']}`}
                  src={
                    require('./assets/img/SketchPng9fc23872803bc1f577f64d43d9e5a24f6c38055a544230505950571bc5aedb1b.png')
                  }
                />
              </div>
              <div className={`${styles['box_39']} flex-row`}>
                <div className={`${styles['text-wrapper_20']} flex-col`}>
                  <span className={`${styles['text_63']}`}>热门</span>
                </div>
              </div>
              <div className={`${styles['text-wrapper_21']} flex-row`}>
                <span className={`${styles['text_64']}`}>产品选型</span>
              </div>
            </div>
            <div className={`${styles['box_40']} flex-row`}>
              <div className={`${styles['image-text_40']} flex-row justify-between`}>
                <img
                  className={`${styles['label_27']}`}
                  src={
                    require('./assets/img/SketchPngb0ea77b1d0e0071b5ed21c071dbaf5c839dcfc0fc32beec76d3bdec5a7fbfb9e.png')
                  }
                />
                <div className={`${styles['text-group_37']} flex-col justify-between`}>
                  <span className={`${styles['text_65']}`}>能源</span>
                  <span className={`${styles['text_66']}`}>助力清洁能源生产，</span>
                </div>
              </div>
            </div>
          </div>
          <div className={`${styles['box_41']} flex-col`}>
            <span className={`${styles['paragraph_8']}`}>
              丰富的应用场景和行业解决方案，为企业赋能、让生产
              <br />
              高效、降本增效
            </span>
            <div className={`${styles['image-text_41']} flex-row justify-between`}>
              <div className={`${styles['group_20']} flex-row`}>
                <div className={`${styles['image-text_42']} flex-row justify-between`}>
                  <span className={`${styles['text-group_38']}`}>电话咨询</span>
                  <img
                    className={`${styles['thumbnail_24']}`}
                    src={
                      require('./assets/img/SketchPng35c0f2e8340648d41a261fbf3a14647c23e57cd7108e01120254985b00721c67.png')
                    }
                  />
                </div>
                <div className={`${styles['image-text_43']} flex-row justify-between`}>
                  <span className={`${styles['text-group_38']}`}>电话咨询</span>
                  <img
                    className={`${styles['thumbnail_24']}`}
                    src={
                      require('./assets/img/SketchPng35c0f2e8340648d41a261fbf3a14647c23e57cd7108e01120254985b00721c67.png')
                    }
                  />
                </div>
              </div>
              <span className={`${styles['text-group_39']}`}>400-880-5656</span>
            </div>
            <img
              className={`${styles['image_5']}`}
              src={
                require('./assets/img/SketchPng0ee43f01bd13f6ba21297c72b1908216c8d8f3811421a22e7f0638fc11277f0e.png')
              }
            />
          </div>
          <div className={`${styles['box_42']} flex-col`}>
            <div className={`${styles['section_12']} flex-row`}>
              {/* <div className={`${styles['section_13']} flex-col justify-between`}>
                <span className={`${styles['text_67']}`}>IT综合服务</span>
                <div className={`${styles['text-group_40']} flex-col justify-between`}>
                  <span className={`${styles['text_68']}`}>ITO人力</span>
                  <span className={`${styles['text_69']}`}>ITO项目</span>
                  <span className={`${styles['text_70']}`}>ITO测试</span>
                </div>
              </div>
              <div className={`${styles['section_14']} flex-col justify-between`}>
                <span className={`${styles['text_71']}`}>企业服务</span>
                <div className={`${styles['text-group_41']} flex-col justify-between`}>
                  <span className={`${styles['text_72']}`}>BPO服务</span>
                  <span className={`${styles['text_73']}`}>NDC&amp;ODC</span>
                  <span className={`${styles['text_74']}`}>知识产权服务</span>
                </div>
              </div>
              <div className={`${styles['section_15']} flex-col justify-between`}>
                <span className={`${styles['text_75']}`}>关于申美</span>
                <div className={`${styles['text-group_42']} flex-col justify-between`}>
                  <span className={`${styles['text_76']}`}>企业简介</span>
                  <span className={`${styles['text_77']}`}>招聘中心</span>
                  <span className={`${styles['text_78']}`}>合作伙伴</span>
                </div>
              </div> */}
              <div className={`${styles['section_16']} flex-col justify-between`}>
                <div className={`${styles['image-text_44']} flex-col justify-between`}>
                  <img
                    className={`${styles['image_6']}`}
                    src={
                      require('./assets/img/SketchPngc6cbbd9032162aa64557e58a63aa4ca3f06762f357621999bb52d7507235eed5.png')
                    }
                  />
                  <span className={`${styles['text-group_43']}`}>上海申美信息科技有限公司</span>
                </div>
                <span className={`${styles['paragraph_9']}`}>
                  地址：上海市普陀区大渡河路388弄5号楼406室
                  <br />
                  电话：13391356946
                  <br />
                  email：surmeta&#64;surmeta.com
                </span>
              </div>
            </div>
            <div className={`${styles['section_17']} flex-row justify-between`}>
              <div className={`${styles['image-text_45']} flex-col justify-between`}>
                <div className={`${styles['box_43']} flex-col`} />
                <span className={`${styles['text-group_44']}`}>官方微信号</span>
              </div>
              <div className={`${styles['image-text_46']} flex-col justify-between`}>
                <div className={`${styles['group_21']} flex-col`} />
                <span className={`${styles['text-group_45']}`}>抖音视频号</span>
              </div>
            </div>
          </div>
        </div>
        <div className={`${styles['box_44']} flex-col`}>
          <div className={`${styles['text-wrapper_22']}`}>
            <span className={`${styles['paragraph_10']}`}>
              沪ICP证0876125号&nbsp;&nbsp;&nbsp;沪ICP备987788号&nbsp;
              <br />
              copyright©
            </span>
            <span className={`${styles['text_79']}`}>SurMeta&nbsp;</span>
            <span className={`${styles['text_80']}`}>法律声明</span>
          </div>
        </div>
      </div>
    );
  }
}
export default DDS_lanhupage_0;
