'use strict';

import React, { Component } from 'react';
import { Switch, NavBar, Checkbox, Radio, Input, Tabs, TabBar, Badge } from 'antd-mobile';

import styles from './assets/index.module.css';

const print = function (value) {};
class DDS_lanhupage_0 extends Component {
  state = {};
  setValue(val) {}
  render() {
    return (
      <div className={`${styles.page} flex-col`}>
        <div className={`${styles['box_1']} flex-col`}>
          <div className={`${styles['group_1']} flex-col`}>
            <div className={`${styles['group_2']} flex-row`}>
              <img
                className={`${styles['image_1']}`}
                src={
                  require('./assets/img/SketchPng9f06d0db570db41de5c026ea08d5b9fd623f507cc4e13b72b7d9ec43ff08d10c.png')
                }
              />
              <span className={`${styles['text_1']}`}>首页</span>
              <span className={`${styles['text_2']}`}>公司简介</span>
              <span className={`${styles['text_3']}`}>主营业务</span>
              <span className={`${styles['text_4']}`}>产品方案</span>
              <span className={`${styles['text_5']}`}>合作伙伴</span>
              <span className={`${styles['text_6']}`}>营业网点</span>
              <span className={`${styles['text_7']}`}>&nbsp;人才招聘</span>
              <span className={`${styles['text_8']}`}>中/</span>
              <span className={`${styles['text_9']}`}>En</span>
              <img
                className={`${styles['label_1']}`}
                src={
                  require('./assets/img/SketchPng891cf14de262b84ebfcf52ec76b81e4dfc293a2ca6a043b18cda43ce54425544.png')
                }
              />
              <span className={`${styles['text_10']}`}>400-880-5656</span>
            </div>
            <span className={`${styles['text_11']}`}>专业的数智化服务商</span>
          </div>
        </div>
        <div className={`${styles['box_2']} flex-col`}>
          <span className={`${styles['text_12']}`}>公司简介</span>
          <img
            className={`${styles['image_2']}`}
            src={
              require('./assets/img/SketchPngb7565c2a0bf16563288b784db875592136146c31ac32867ed742c2e769bc921a.png')
            }
          />
          <div className={`${styles['group_3']} flex-row justify-between`}>
            <span className={`${styles['text_13']}`}>
              公司立足上海、服务全国，为金融、汽车、互联网、通讯、数字科技、制造、零售、房地产、医疗、教育、交通、政务、航空、物流、能源、环保等行业客户，提供数智化产品和科技服务，使客户聚焦自身核心主业，更专注与高效实现商业成功。
            </span>
            <div className={`${styles['box_3']} flex-col justify-between`}>
              <div className={`${styles['image-wrapper_1']} flex-col`}>
                <img
                  className={`${styles['label_2']}`}
                  src={
                    require('./assets/img/SketchPng4865c29fdca52372ca94024eca78ded09ad4c881953456b8ac06dbd13605e750.png')
                  }
                />
              </div>
              <div className={`${styles['image-wrapper_2']} flex-col`}>
                <img
                  className={`${styles['label_3']}`}
                  src={
                    require('./assets/img/SketchPng3cf5aed6be634876fa86d0106a713af639ea10ac425b846b3cad51f4294ea66a.png')
                  }
                />
              </div>
            </div>
          </div>
          <div className={`${styles['group_4']} flex-col`}>
            <div className={`${styles['image-text_1']} flex-row justify-between`}>
              <img
                className={`${styles['thumbnail_1']}`}
                src={
                  require('./assets/img/SketchPng8d9fd0b4b7e251e0b23f0fd481f881efbbeb385e56b980b22a7ec4e60cf456dc.png')
                }
              />
              <span className={`${styles['text-group_1']}`}>企业文化</span>
            </div>
            <div className={`${styles['section_1']} flex-row justify-between`}>
              <div className={`${styles['group_5']} flex-col`}>
                <div className={`${styles['image-text_2']} flex-col justify-between`}>
                  <img
                    className={`${styles['label_4']}`}
                    src={
                      require('./assets/img/SketchPng7aacff8e1be9b61450422d8b2a418118de9da96b8d9459cdff919b8008fa28bf.png')
                    }
                  />
                  <span className={`${styles['text-group_2']}`}>愿景</span>
                </div>
                <span className={`${styles['paragraph_1']}`}>
                  成为一家客户满意、员工幸福的数智化服务长青科技企业。
                  <br />
                  <br />
                </span>
              </div>
              <div className={`${styles['group_6']} flex-col`}>
                <img
                  className={`${styles['label_5']}`}
                  src={
                    require('./assets/img/SketchPng2043c49382c95a052eadbede37c960dcc18b14015e3a5877f0d7a51ea45bc693.png')
                  }
                />
                <span className={`${styles['text_14']}`}>使命</span>
                <span className={`${styles['paragraph_2']}`}>
                  以创新的科技与服务，让社会更高效与美好。
                  <br />
                  <br />
                </span>
              </div>
              <div className={`${styles['image-text_3']} flex-col`}>
                <img
                  className={`${styles['label_6']}`}
                  src={
                    require('./assets/img/SketchPng62c4f868a251048acbddd6627b19cc97158912c04e5a70b377653bf435039d45.png')
                  }
                />
                <div className={`${styles['text-group_3']} flex-col justify-between`}>
                  <span className={`${styles['text_15']}`}>价值观</span>
                  <span className={`${styles['paragraph_3']}`}>
                    客户为本、价值为纲、正念正行、务实创新、同创共享、福报社会。
                    <br />
                    <br />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className={`${styles['group_7']} flex-col`}>
            <div className={`${styles['section_2']} flex-row`}>
              <div className={`${styles['block_1']} flex-col justify-between`}>
                <span className={`${styles['text_16']}`}>IT综合服务</span>
                <div className={`${styles['text-group_4']} flex-col justify-between`}>
                  <span className={`${styles['text_17']}`}>ITO人力</span>
                  <span className={`${styles['text_18']}`}>ITO项目</span>
                  <span className={`${styles['text_19']}`}>ITO测试</span>
                </div>
              </div>
              <div className={`${styles['block_2']} flex-col justify-between`}>
                <span className={`${styles['text_20']}`}>企业服务</span>
                <div className={`${styles['text-group_5']} flex-col justify-between`}>
                  <span className={`${styles['text_21']}`}>BPO服务</span>
                  <span className={`${styles['text_22']}`}>NDC&amp;ODC</span>
                  <span className={`${styles['text_23']}`}>知识产权服务</span>
                </div>
              </div>
              <div className={`${styles['block_3']} flex-col justify-between`}>
                <span className={`${styles['text_24']}`}>关于申美</span>
                <div className={`${styles['text-group_6']} flex-col justify-between`}>
                  <span className={`${styles['text_25']}`}>企业简介</span>
                  <span className={`${styles['text_26']}`}>招聘中心</span>
                  <span className={`${styles['text_27']}`}>合作伙伴</span>
                </div>
              </div>
              <div className={`${styles['block_4']} flex-col justify-between`}>
                <div className={`${styles['image-text_4']} flex-col justify-between`}>
                  <img
                    className={`${styles['image_3']}`}
                    src={
                      require('./assets/img/SketchPngc6cbbd9032162aa64557e58a63aa4ca3f06762f357621999bb52d7507235eed5.png')
                    }
                  />
                  <span className={`${styles['text-group_7']}`}>上海申美信息科技有限公司</span>
                </div>
                <span className={`${styles['paragraph_4']}`}>
                  地址：上海市普陀区大渡河路388弄5号楼406室
                  <br />
                  电话：13391356946
                  <br />
                  email：surmeta&#64;surmeta.com
                </span>
              </div>
            </div>
            <div className={`${styles['section_3']} flex-row justify-between`}>
              <div className={`${styles['image-text_5']} flex-col justify-between`}>
                <div className={`${styles['group_8']} flex-col`} />
                <span className={`${styles['text-group_8']}`}>官方微信号</span>
              </div>
              <div className={`${styles['image-text_6']} flex-col justify-between`}>
                <div className={`${styles['group_9']} flex-col`} />
                <span className={`${styles['text-group_9']}`}>抖音视频号</span>
              </div>
            </div>
          </div>
          <div className={`${styles['group_10']} flex-col`}>
            <div className={`${styles['image-wrapper_3']} flex-col`}>
              <img
                className={`${styles['image_4']}`}
                src={
                  require('./assets/img/SketchPng6723eac3ac83c63bda95850749a39a9cc8fd5ab06b563ab15cda1dad0e971329.png')
                }
              />
            </div>
          </div>
        </div>
        <div className={`${styles['box_4']} flex-col`}>
          <div className={`${styles['text-wrapper_1']}`}>
            <span className={`${styles['paragraph_5']}`}>
              沪ICP证0876125号&nbsp;&nbsp;&nbsp;沪ICP备987788号&nbsp;
              <br />
              copyright©
            </span>
            <span className={`${styles['text_28']}`}>SurMeta&nbsp;</span>
            <span className={`${styles['text_29']}`}>法律声明</span>
          </div>
        </div>
      </div>
    );
  }
}
export default DDS_lanhupage_0;
