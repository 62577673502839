'use strict';

import React, { Component } from 'react';
import {Nav} from '../component/nav';
import {Footer} from '../component/footer';

import './solution.css';


class Solution extends Component {


  render() {
    return (
      <div className='page-main'>
        <Nav curr="产品方案"></Nav>
       
         <div className='app-solution-banner'>
            <div className='app-business-banner-t1'>
                使客户聚焦自身核心主业
            </div>
            <div className='app-solution-banner-t2'>
                更专注与高效实现商业成功
            </div>
        </div>

        <div className='app-flex'>
            <div className='app-solution-hot-title'>
               <span>{'>'}</span>  热门行业解决方案
            </div>
        </div>

        <div className='app-flex'>
            <div className='app-solution-hot-item' data-right='true'>
                <div className='app-solution-hot-item-up' bg='1'>
                    <div className='app-solution-hot-item-up-content'>
                        <span data-type='name'>银行</span>
                        <span className='app-solution-hot-tag'>热门</span>
                    </div>
                </div>
                <div className='app-solution-hot-item-down'>
                    帮助银行客户在数字化时代保持领先，调升客户满意度和服务效率
                </div>
            </div>

              <div className='app-solution-hot-item' data-right='true'>
                <div className='app-solution-hot-item-up' bg='2'>
                    <div className='app-solution-hot-item-up-content'>
                        <span data-type='name'>保险</span>
                        <span className='app-solution-hot-tag'>热门</span>
                    </div>
                </div>
                <div className='app-solution-hot-item-down'>
                    帮助保险客户在数字化时代保持领先，调升客户满意度和服务效率
                </div>
            </div>

              <div className='app-solution-hot-item' data-right='true'>
                <div className='app-solution-hot-item-up' bg='3'>
                    <div className='app-solution-hot-item-up-content'>
                        <span data-type='name'>汽车</span>
                        <span className='app-solution-hot-tag'>热门</span>
                    </div>
                </div>
                <div className='app-solution-hot-item-down'>
                    帮助车企客户在数字化时代保持领先，调升客户满意度和服务效率
                </div>
            </div>
        </div>


    <div className='app-flex app-solution-title' style={{marginTop:30}}>细分领域解决方案</div>
        <div style={{width:'100%',textAlign:'center',marginBottom:30,marginTop:10}}>
                    <img
                        referrerPolicy="no-referrer" 
                        src={
                            require('./img/jiantou.png')
                            }
                        ></img>
        </div>

        <SolutionNav title='金融' type='jr'/>
         <SolutionItemList 
            items = {[
                {
                    type:'jr_fjr',
                    title:'泛金融' ,
                    hot:true,
                    text:'赋能金融行业数字化转型，助力客户业务创新和发展'
                },
                 {
                    type:'jr_yh',
                    title:'银行' ,
                    hot:true,
                    text:'帮助银行客户在数字化时代保持领先'
                },
                {
                    type:'jr_zj',
                    title:'证券' ,
                    hot:true,
                    text:'助力客户在满足监管合规的前提下实现决策智能与降本增效'
                },

            ]}
         />   
          <SolutionItemList 
            items = {[
                {
                    type:'jr_bx',
                    title:'保险' ,
                    hot:true,
                    text:'赋能金融行业数字化转型，助力客户业务创新和发展'
                },
                 {
                    type:'jr_hj',
                    title:'互金' ,
                    hot:false,
                    text:'帮助银行客户在数字化时代保持领先'
                },
                {
                    type:'blank'
                },

            ]}
         />      

         <SolutionNav title='互联网' type='hlw'/>      
        <SolutionItemList 
                    items = {[
                        {
                            type:'hlw_xls',
                            title:'新零售' ,
                            hot:true,
                            text:'互联网零售新浪潮，助力企业出海'
                        },
                        {
                            type:'hlw_zhyl',
                            title:'智慧医疗' ,
                            hot:false,
                            text:'帮助银行客户在数字化时代保持领先'
                        },
                        {
                            type:'hlw_zhny',
                            title:'智慧农业' ,
                            hot:false,
                            text:'助力客户在满足监管合规的前提下实现决策智能与降本增效'
                        },

                    ]}
                />  

        <SolutionItemList 
            items = {[
                {
                    type:'hlw_zhcx',
                    title:'智慧出行' ,
                    hot:false,
                    text:'互联网零售新浪潮，助力企业出海'
                },
                {
                    type:'hlw_zhgy',
                    title:'智慧公园' ,
                    hot:false,
                    text:'帮助银行客户在数字化时代保持领先'
                },
                {
                    type:'hlw_zhjy',
                    title:'智慧教育' ,
                    hot:false,
                    text:'助力客户在满足监管合规的前提下实现决策智能与降本增效'
                },

            ]}
        />  
         <SolutionItemList 
            items = {[
                {
                    type:'hlw_hymh',
                    title:'行业门户' ,
                    hot:false,
                    text:'互联网零售新浪潮，助力企业出海'
                },
                {
                    type:'hlw_ltbk',
                    title:'论坛博客' ,
                    hot:false,
                    text:'帮助银行客户在数字化时代保持领先'
                },
                {
                    type:'hlw_zbsp',
                    title:'直播视频' ,
                    hot:true,
                    text:'助力客户在满足监管合规的前提下实现决策智能与降本增效'
                },

            ]}
        /> 

         <SolutionNav title='通信' type='tx'/>  
          <SolutionItemList 
            items = {[
                {
                    type:'tx_5g',
                    title:'5G/高速网' ,
                    hot:true,
                    text:'运用大数据技术和挖掘模型，助力客户洞察先机，开发新的业务潜能…'
                },
                { type:'blank', },{ type:'blank', }
            ]}
        />   

         <SolutionNav title='制造业' type='zzy'/>   
          <SolutionItemList 
            items = {[
                {
                    type:'zzy_qcy',
                    title:'汽车业' ,
                    hot:true,
                    text:'以客户为中心，助力汽车行业的智能化和网联化…'
                },
                {
                    type:'zzy_gy40',
                    title:'工业4.0' ,
                    hot:true,
                    text:'为制造业实现柔性生产，按需定制，智能化工厂'
                },
                {
                    type:'blank'
                },

            ]}
        />  

         <SolutionNav title='服务业' type='fwy'/> 
         <SolutionItemList 
            items = {[
                {
                    type:'fwy_fwzx',
                    title:'服务咨询' ,
                    hot:true,
                    text:'数字化提升服务业运营效率与客户满意度'
                },
                {
                    type:'fwy_ds',
                    title:'电商' ,
                    hot:true,
                    text:'助力电商企业全模式服务全球市场'
                },
                {
                    type:'blank'
                },

            ]}
        />   

         <SolutionNav title='政企' type='zq'/> 
         <SolutionItemList 
            items = {[
                {
                    type:'zq_zhcs',
                    title:'智慧城市' ,
                    hot:true,
                    text:'数字重构, 合作共建, 赋能智能城市新生态'
                },
                {
                    type:'zq_zhaf',
                    title:'智慧安防' ,
                    hot:false,
                    text:'结合人脸识别技术，安防管理数字化、集成化、智能化。'
                },
                {
                    type:'zq_zhzw',
                    title:'智慧政务' ,
                    hot:false,
                    text:'在线政务公开、办理。'
                },

            ]}
        /> 

         <SolutionNav title='其他' type='qt'/>     
         <SolutionItemList 
            items = {[
                {
                    type:'qt_ict',
                    title:'ICT软硬一体综合解决方案' ,
                    hot:false,
                    text:'产品选型'
                },
                {
                    type:'qt_ny',
                    title:'能源' ,
                    hot:false,
                    text:'助力清洁能源生产，'
                },
                {
                    type:'blank',
                },

            ]}
        />       

        <div style={{width:'100%',height:100}}></div>         
       
        <Footer/>
 
       </div>
    )
  }
}


const SolutionNav = (props) =>{
    return (<div className='app-flex' style={{marginTop:10 , marginBottom:20}}>
            <div className='app-solution-nav' data-type={props.type}>
                <span>{'>'}</span>{props.title}
            </div>
    </div>)
}

const SolutionItemList = (props) =>{

 return (<div className='app-flex' >
     {
                    props.items.map((d,i)=>{
                        let isRight = (i != 2);
                        if(d.type=='blank'){
                            return <div key={i} className='app-solution-item' data-right={isRight} data-type='blank'></div> 
                        }
                        else{
                            return ( <div className='app-solution-item' data-right={isRight}>
                                    <div className='app-solution-hot-item-img-div'>
                                        <div data-type={d.type}></div>
                                    </div>
                                    <div className='app-solution-hot-item-text'>
                                        <div className='app-solution-hot-item-title'>
                                            <span className='app-solution-hot-item-title-text'>{d.title}</span>
                                            {d.hot?<span className='app-solution-hot-tag'>热门</span> : null
                                            }
                                        </div>
                                        <div className='app-solution-hot-item-text-text'>
                                            {d.text}
                                        </div>
                                    </div>
                            </div>);
                        }
                        
                    })
                }
    </div>)

}




export default Solution;
