'use strict';

import React, { Component } from 'react';
import {Nav} from '../component/nav';
import {Footer} from '../component/footer';
import './location.css';


class MyLocation extends Component {


  render() {
    return (
      <div className='page-main'>
        <Nav curr="营业网点"></Nav>
       
         <div className='app-location-banner'>
            <div className='app-location-banner-title'>
                欢迎致电或莅临
            </div>
        </div>

        <div className='app-flex app-location-title' style={{marginTop:30}}>公司地址</div>
        <div style={{width:'100%',textAlign:'center',marginBottom:30,marginTop:10}}>
                    <img
                        referrerPolicy="no-referrer" 
                        src={
                            require('./img/jiantou.png')
                            }
                        ></img>
        </div>

        <div className='app-flex'>
               <div className='app-location-dec'>
                      地址： 上海市普陀区大渡河路388弄5号406室 <br/>
                      Tel:     13391656946  <br/>
                      Email: surmeta@surmeta.com <br/>
                </div>  
                <div className='app-laction-wx-outer'>
                  <div className='app-laction-wx'></div>
                  <div className='app-laction-wx-down'>官方微信号</div>
                </div> 
                <div className='app-laction-wx-outer'>
                  <div className='app-laction-wx'></div>
                  <div className='app-laction-wx-down'>抖音视频号</div>
                </div>           
        </div>


        <div className='app-location-map-bg'>
                <img 
                className="app-location-map-location" 
                referrerPolicy="no-referrer" 
                src={
                    require('./img/location-dw.png')
                    }
                ></img>           
        </div>

       
       
        <Footer/>
 
       </div>
    )
  }
}



export default MyLocation;
