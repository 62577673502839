'use strict';

import React, { Component } from 'react';
import { Switch, NavBar, Checkbox, Radio, Input, Tabs, TabBar, Badge } from 'antd-mobile';

import styles from './assets/index.module.css';

const print = function (value) {};
class DDS_lanhupage_0 extends Component {
  state = {};
  setValue(val) {}
  render() {
    return (
      <div className={`${styles.page} flex-col`}>
        <div className={`${styles['box_1']} flex-col`}>
          <div className={`${styles['box_2']} flex-row`}>
            <img
              className={`${styles['image_1']}`}
              src={
                require('./assets/img/SketchPng9f06d0db570db41de5c026ea08d5b9fd623f507cc4e13b72b7d9ec43ff08d10c.png')
              }
            />
            <span className={`${styles['text_1']}`}>首页</span>
            <span className={`${styles['text_2']}`}>公司简介</span>
            <span className={`${styles['text_3']}`}>主营业务</span>
            <span className={`${styles['text_4']}`}>产品方案</span>
            <span className={`${styles['text_5']}`}>合作伙伴</span>
            <span className={`${styles['text_6']}`}>营业网点</span>
            <span className={`${styles['text_7']}`}>&nbsp;人才招聘</span>
            <span className={`${styles['text_8']}`}>中/</span>
            <span className={`${styles['text_9']}`}>En</span>
            <img
              className={`${styles['label_1']}`}
              src={
                require('./assets/img/SketchPng891cf14de262b84ebfcf52ec76b81e4dfc293a2ca6a043b18cda43ce54425544.png')
              }
            />
            <span className={`${styles['text_10']}`}>400-880-5656</span>
          </div>
          <span className={`${styles['text_11']}`}>双向奔赴&nbsp;一起成长</span>
          <div className={`${styles['box_3']} flex-col justify-end`}>
            <div className={`${styles['text-wrapper_1']} flex-row`}>
              <span className={`${styles['text_12']}`}>产品</span>
              <span className={`${styles['text_13']}`}>开发</span>
              <span className={`${styles['text_14']}`}>运维</span>
              <span className={`${styles['text_15']}`}>设计</span>
              <span className={`${styles['text_16']}`}>服务</span>
            </div>
            <div className={`${styles['block_1']} flex-row`}>
              <div className={`${styles['section_1']} flex-col`} />
            </div>
          </div>
        </div>
        <div className={`${styles['box_4']} flex-col`}>
          <img
            className={`${styles['image_2']}`}
            src={
              require('./assets/img/SketchPnge5bede092dfa68525abcc96b679d45ea12b7556f0c4486cfc18e01d11dea3f7c.png')
            }
          />
          <div className={`${styles['box_5']} flex-row`}>
            <img
              className={`${styles['thumbnail_1']}`}
              src={
                require('./assets/img/SketchPng8d9fd0b4b7e251e0b23f0fd481f881efbbeb385e56b980b22a7ec4e60cf456dc.png')
              }
            />
            <span className={`${styles['text_17']}`}>产品</span>
            <div className={`${styles['box_6']} flex-col`} />
          </div>
          <div className={`${styles['box_7']} flex-row justify-between`}>
            <div className={`${styles['section_2']} flex-col justify-between`}>
              <div className={`${styles['text-wrapper_2']} flex-col`}>
                <span className={`${styles['text_18']}`}>B端产品经理</span>
              </div>
              <div className={`${styles['text-wrapper_3']} flex-col`}>
                <span className={`${styles['text_19']}`}>电商产品经理</span>
              </div>
            </div>
            <div className={`${styles['section_3']} flex-row justify-end`}>
              <div className={`${styles['text-wrapper_4']} flex-col`}>
                <span className={`${styles['text_20']}`}>工作职责</span>
                <span className={`${styles['paragraph_1']}`}>
                  1&nbsp;与分行一起拜访客户，挖堀客户需求，高效对话并理解客户需求，并围绕客户需求助力分行业务制定‘科技＋金融’解决方素，对客户提出的科技需求进行远程支持或现场指导。
                  <br />
                  2&nbsp;梳理业务流程、提升分行需求分析质量，撰写高质量需求说明书，提升与总行产品部门沟通效率，快速推进需求澄清等事项，
                  <br />
                  3&nbsp;跟踪总分行在系统和科技产品需求实施进度，缩短问题解決路径，及时推进项目落地。
                </span>
                <span className={`${styles['text_21']}`}>岗位要求</span>
                <span className={`${styles['text_22']}`}>
                  1&nbsp;与分行一起拜访客户，挖堀客户需求，高效对话并理解客户需求，并围绕客户需求助力分行业务制定
                </span>
              </div>
              <div className={`${styles['group_1']} flex-col`}>
                <div className={`${styles['box_8']} flex-col`} />
              </div>
            </div>
          </div>
          <div className={`${styles['box_9']} flex-row`}>
            <div className={`${styles['image-text_1']} flex-row justify-between`}>
              <img
                className={`${styles['thumbnail_2']}`}
                src={
                  require('./assets/img/SketchPng8d9fd0b4b7e251e0b23f0fd481f881efbbeb385e56b980b22a7ec4e60cf456dc.png')
                }
              />
              <span className={`${styles['text-group_1']}`}>开发</span>
            </div>
            <div className={`${styles['group_2']} flex-col`} />
          </div>
          <div className={`${styles['box_10']} flex-row justify-between`}>
            <div className={`${styles['box_11']} flex-col justify-between`}>
              <div className={`${styles['text-wrapper_5']} flex-col`}>
                <span className={`${styles['text_23']}`}>Java&nbsp;开发工程师</span>
              </div>
              <div className={`${styles['text-wrapper_6']} flex-col`}>
                <span className={`${styles['text_24']}`}>大数据工程师</span>
              </div>
              <div className={`${styles['text-wrapper_7']} flex-col`}>
                <span className={`${styles['text_25']}`}>前端开发</span>
              </div>
            </div>
            <div className={`${styles['text-wrapper_8']} flex-col`}>
              <span className={`${styles['text_26']}`}>工作职责</span>
              <span className={`${styles['paragraph_2']}`}>
                1&nbsp;与分行一起拜访客户，挖堀客户需求，高效对话并理解客户需求，并围绕客户需求助力分行业务制定‘科技＋金融’解决方素，对客户提出的科技需求进行远程支持或现场指导。
                <br />
                2&nbsp;梳理业务流程、提升分行需求分析质量，撰写高质量需求说明书，提升与总行产品部门沟通效率，快速推进需求澄清等事项，
                <br />
                3&nbsp;跟踪总分行在系统和科技产品需求实施进度，缩短问题解決路径，及时推进项目落地。
              </span>
              <span className={`${styles['text_27']}`}>岗位要求</span>
              <span className={`${styles['text_28']}`}>
                1&nbsp;与分行一起拜访客户，挖堀客户需求，高效对话并理解客户需求，并围绕客户需求助力分行业务制定
              </span>
            </div>
          </div>
          <div className={`${styles['box_12']} flex-row`}>
            <div className={`${styles['image-text_2']} flex-row justify-between`}>
              <img
                className={`${styles['thumbnail_3']}`}
                src={
                  require('./assets/img/SketchPng8d9fd0b4b7e251e0b23f0fd481f881efbbeb385e56b980b22a7ec4e60cf456dc.png')
                }
              />
              <span className={`${styles['text-group_2']}`}>运维</span>
            </div>
            <div className={`${styles['box_13']} flex-col`} />
          </div>
          <div className={`${styles['box_14']} flex-row justify-between`}>
            <div className={`${styles['group_3']} flex-col justify-between`}>
              <div className={`${styles['text-wrapper_9']} flex-col`}>
                <span className={`${styles['text_29']}`}>大数据标注</span>
              </div>
              <div className={`${styles['text-wrapper_10']} flex-col`}>
                <span className={`${styles['text_30']}`}>直播中控</span>
              </div>
              <div className={`${styles['text-wrapper_11']} flex-col`}>
                <span className={`${styles['text_31']}`}>硬件运维</span>
              </div>
            </div>
            <div className={`${styles['text-wrapper_12']} flex-col`}>
              <span className={`${styles['text_32']}`}>工作职责</span>
              <span className={`${styles['paragraph_3']}`}>
                1&nbsp;与分行一起拜访客户，挖堀客户需求，高效对话并理解客户需求，并围绕客户需求助力分行业务制定‘科技＋金融’解决方素，对客户提出的科技需求进行远程支持或现场指导。
                <br />
                2&nbsp;梳理业务流程、提升分行需求分析质量，撰写高质量需求说明书，提升与总行产品部门沟通效率，快速推进需求澄清等事项，
                <br />
                3&nbsp;跟踪总分行在系统和科技产品需求实施进度，缩短问题解決路径，及时推进项目落地。
              </span>
              <span className={`${styles['text_33']}`}>岗位要求</span>
              <span className={`${styles['text_34']}`}>
                1&nbsp;与分行一起拜访客户，挖堀客户需求，高效对话并理解客户需求，并围绕客户需求助力分行业务制定
              </span>
            </div>
          </div>
          <div className={`${styles['box_15']} flex-row`}>
            <div className={`${styles['image-text_3']} flex-row justify-between`}>
              <img
                className={`${styles['thumbnail_4']}`}
                src={
                  require('./assets/img/SketchPng8d9fd0b4b7e251e0b23f0fd481f881efbbeb385e56b980b22a7ec4e60cf456dc.png')
                }
              />
              <span className={`${styles['text-group_3']}`}>设计</span>
            </div>
            <div className={`${styles['group_4']} flex-col`} />
          </div>
          <div className={`${styles['box_16']} flex-row justify-between`}>
            <div className={`${styles['group_5']} flex-col justify-between`}>
              <div className={`${styles['text-wrapper_13']} flex-col`}>
                <span className={`${styles['text_35']}`}>B端页面设计师</span>
              </div>
              <div className={`${styles['text-wrapper_14']} flex-col`}>
                <span className={`${styles['text_36']}`}>C4D设计师</span>
              </div>
              <div className={`${styles['text-wrapper_15']} flex-col`}>
                <span className={`${styles['text_37']}`}>用户体验设计</span>
              </div>
            </div>
            <div className={`${styles['text-wrapper_16']} flex-col`}>
              <span className={`${styles['text_38']}`}>工作职责</span>
              <span className={`${styles['paragraph_4']}`}>
                1&nbsp;与分行一起拜访客户，挖堀客户需求，高效对话并理解客户需求，并围绕客户需求助力分行业务制定‘科技＋金融’解决方素，对客户提出的科技需求进行远程支持或现场指导。
                <br />
                2&nbsp;梳理业务流程、提升分行需求分析质量，撰写高质量需求说明书，提升与总行产品部门沟通效率，快速推进需求澄清等事项，
                <br />
                3&nbsp;跟踪总分行在系统和科技产品需求实施进度，缩短问题解決路径，及时推进项目落地。
              </span>
              <span className={`${styles['text_39']}`}>岗位要求</span>
              <span className={`${styles['text_40']}`}>
                1&nbsp;与分行一起拜访客户，挖堀客户需求，高效对话并理解客户需求，并围绕客户需求助力分行业务制定
              </span>
            </div>
          </div>
          <div className={`${styles['box_17']} flex-row`}>
            <div className={`${styles['image-text_4']} flex-row justify-between`}>
              <img
                className={`${styles['thumbnail_5']}`}
                src={
                  require('./assets/img/SketchPng8d9fd0b4b7e251e0b23f0fd481f881efbbeb385e56b980b22a7ec4e60cf456dc.png')
                }
              />
              <span className={`${styles['text-group_4']}`}>服务</span>
            </div>
            <div className={`${styles['box_18']} flex-col`} />
          </div>
          <div className={`${styles['box_19']} flex-row justify-between`}>
            <div className={`${styles['group_6']} flex-col justify-between`}>
              <div className={`${styles['text-wrapper_17']} flex-col`}>
                <span className={`${styles['text_41']}`}>前台接待</span>
              </div>
              <div className={`${styles['text-wrapper_18']} flex-col`}>
                <span className={`${styles['text_42']}`}>客服</span>
              </div>
            </div>
            <div className={`${styles['text-wrapper_19']} flex-col`}>
              <span className={`${styles['text_43']}`}>工作职责</span>
              <span className={`${styles['paragraph_5']}`}>
                1&nbsp;与分行一起拜访客户，挖堀客户需求，高效对话并理解客户需求，并围绕客户需求助力分行业务制定‘科技＋金融’解决方素，对客户提出的科技需求进行远程支持或现场指导。
                <br />
                2&nbsp;梳理业务流程、提升分行需求分析质量，撰写高质量需求说明书，提升与总行产品部门沟通效率，快速推进需求澄清等事项，
                <br />
                3&nbsp;跟踪总分行在系统和科技产品需求实施进度，缩短问题解決路径，及时推进项目落地。
              </span>
              <span className={`${styles['text_44']}`}>岗位要求</span>
              <span className={`${styles['text_45']}`}>
                1&nbsp;与分行一起拜访客户，挖堀客户需求，高效对话并理解客户需求，并围绕客户需求助力分行业务制定
              </span>
            </div>
          </div>
          <div className={`${styles['box_20']} flex-col`}>
            <div className={`${styles['section_4']} flex-row`}>
              <div className={`${styles['section_5']} flex-col justify-between`}>
                <span className={`${styles['text_46']}`}>IT综合服务</span>
                <div className={`${styles['text-group_5']} flex-col justify-between`}>
                  <span className={`${styles['text_47']}`}>ITO人力</span>
                  <span className={`${styles['text_48']}`}>ITO项目</span>
                  <span className={`${styles['text_49']}`}>ITO测试</span>
                </div>
              </div>
              <div className={`${styles['section_6']} flex-col justify-between`}>
                <span className={`${styles['text_50']}`}>企业服务</span>
                <div className={`${styles['text-group_6']} flex-col justify-between`}>
                  <span className={`${styles['text_51']}`}>BPO服务</span>
                  <span className={`${styles['text_52']}`}>NDC&amp;ODC</span>
                  <span className={`${styles['text_53']}`}>知识产权服务</span>
                </div>
              </div>
              <div className={`${styles['section_7']} flex-col justify-between`}>
                <span className={`${styles['text_54']}`}>关于申美</span>
                <div className={`${styles['text-group_7']} flex-col justify-between`}>
                  <span className={`${styles['text_55']}`}>企业简介</span>
                  <span className={`${styles['text_56']}`}>招聘中心</span>
                  <span className={`${styles['text_57']}`}>合作伙伴</span>
                </div>
              </div>
              <div className={`${styles['section_8']} flex-col justify-between`}>
                <div className={`${styles['image-text_5']} flex-col justify-between`}>
                  <img
                    className={`${styles['image_3']}`}
                    src={
                      require('./assets/img/SketchPngc6cbbd9032162aa64557e58a63aa4ca3f06762f357621999bb52d7507235eed5.png')
                    }
                  />
                  <span className={`${styles['text-group_8']}`}>上海申美信息科技有限公司</span>
                </div>
                <span className={`${styles['paragraph_6']}`}>
                  地址：上海市普陀区大渡河路388弄5号楼406室
                  <br />
                  电话：13391356946
                  <br />
                  email：surmeta&#64;surmeta.com
                </span>
              </div>
            </div>
            <div className={`${styles['section_9']} flex-row justify-between`}>
              <div className={`${styles['image-text_6']} flex-col justify-between`}>
                <div className={`${styles['box_21']} flex-col`} />
                <span className={`${styles['text-group_9']}`}>官方微信号</span>
              </div>
              <div className={`${styles['image-text_7']} flex-col justify-between`}>
                <div className={`${styles['box_22']} flex-col`} />
                <span className={`${styles['text-group_10']}`}>抖音视频号</span>
              </div>
            </div>
          </div>
        </div>
        <div className={`${styles['box_23']} flex-col`}>
          <div className={`${styles['text-wrapper_20']}`}>
            <span className={`${styles['paragraph_7']}`}>
              沪ICP证0876125号&nbsp;&nbsp;&nbsp;沪ICP备987788号&nbsp;
              <br />
              copyright©
            </span>
            <span className={`${styles['text_58']}`}>SurMeta&nbsp;</span>
            <span className={`${styles['text_59']}`}>法律声明</span>
          </div>
        </div>
      </div>
    );
  }
}
export default DDS_lanhupage_0;
