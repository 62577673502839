import React from "react";

export const MapWorld = () =>{

    return (<div className="app-index-location-map-china">

        <div className="app-index-location-map-left">
            <span>中国</span>
            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAJKADAAQAAAABAAAAJAAAAAAqDuP8AAAAoklEQVRYCe2YwQmAMAxFRcRZvDuk03hwMVeIP2AglDY3TcUfKEnby/O10NZhYPzVgIhMaHMX33/D7MhHOpSDQSkn2pJmqQKzEkYN0ExrH9AMzbQMtMa5Z75oBqv2aGyllLEc6LIPJ3q30euEhp7geYemGQIEoUxGmGkq1OMmacrJCEuaCvW4SZpyMsKyYirvoWikDir/KV1A9fGzwaCY3zBwAfJ7PsZWdqZWAAAAAElFTkSuQmCC"></img>
        </div>

         <div className="app-index-location-map-content">
           
           <MapLocation 
                title="旧金山"
                top="166px"
                left="0px"
           />

            <MapLocation 
                title="伦敦"
                top="55px"
                left="280px"
           />

            <MapLocation 
                title="北京"
                top="48px"
                left="580px"
           />

             <MapLocation 
                title="上海"
                top="55px"
                left="600px"
           />

             <MapLocation 
                title="新加坡"
                top="70px"
                left="620px"
           />
        </div>
           
          

    </div>)
}



const MapLocation = (props) =>{
    return (
            <div className="app-index-map-location-city" style={{top:props.top,left:props.left}}>
                <div className="app-index-map-location-city-name">{props.title}</div>
                <div className="app-index-map-location-city-content">
                <div className="app-index-map-location-outer">
                    <div className="app-index-map-location-inner"></div>
                </div>
                </div>
            </div>
    );

}