import React, {Component} from 'react'
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom'

import index from './view/index/index.jsx';
import about from './view/about/index.jsx';
import Business from './view/business/index.jsx';
import Solution from './view/solution/index.jsx';
import Partner from './view/partner/index.jsx';
import MyLocation from './view/location/index.jsx';
import Recruit from './view/recruit/index.jsx';

import lanhu_hangyejiejuefanganxiangqingfanjinrong from './view/lanhu_hangyejiejuefanganxiangqingfanjinrong/index.jsx'
import lanhu_rencaizhaopin from './view/lanhu_rencaizhaopin/index.jsx'
import lanhu_zhuyingyewu from './view/lanhu_zhuyingyewu/index.jsx'
import lanhu_hezuohuoban from './view/lanhu_hezuohuoban/index.jsx'
import lanhu_guanyushenmeijianjie from './view/lanhu_guanyushenmeijianjie/index.jsx'
import lanhu_shouye1jiaohu from './view/lanhu_shouye1jiaohu/index.jsx'
import lanhu_shouye1 from './view/index/index.jsx'
import lanhu_chanpinfangan from './view/lanhu_chanpinfangan/index.jsx'


class App extends Component {
    render() {
        return (
            <Router>
                <div>
                    <Switch>
                        <Route exact path="/" component={index} />
                        <Route exact path="/about" component={about} />
                        <Route exact path="/business" component={Business} />
                        <Route exact path="/solution" component={Solution} />
                        <Route exact path="/partner" component={Partner} />
                        <Route exact path="/location" component={MyLocation} />
                        <Route exact path="/recruit" component={Recruit} />
                        
                        <Route exact path="/lanhu_hangyejiejuefanganxiangqingfanjinrong" component={lanhu_hangyejiejuefanganxiangqingfanjinrong} />
                        <Route exact path="/lanhu_rencaizhaopin" component={lanhu_rencaizhaopin} />
                        <Route exact path="/lanhu_zhuyingyewu" component={lanhu_zhuyingyewu} />
                        <Route exact path="/lanhu_hezuohuoban" component={lanhu_hezuohuoban} />
                        <Route exact path="/lanhu_guanyushenmeijianjie" component={lanhu_guanyushenmeijianjie} />
                        <Route exact path="/lanhu_shouye1jiaohu" component={lanhu_shouye1jiaohu} />
                        <Route exact path="/lanhu_shouye1" component={lanhu_shouye1} />
                        <Route exact path="/lanhu_chanpinfangan" component={lanhu_chanpinfangan} />
                    </Switch>
                </div>
            </Router>
        )
    }
}

export default App
