'use strict';

import React, { Component } from 'react';
import {Nav} from '../component/nav';
import {Footer} from '../component/footer';
import {Cst} from '../index/cst';

import './partner.css';


class Partner extends Component {

  state = {mapState:'zg'}

  render() {
    return (
      <div className='page-main'>
        <Nav curr="合作伙伴"></Nav>
       
         <div className='app-partner-banner'>
            <div className='app-partner-banner-title'>
                共创双赢
            </div>
        </div>

        <div className='app-partner-main'>
            <Cst/>

        </div>

       

     
       <div style={{width:'100%',height:100}}></div>
       
        <Footer/>
 
       </div>
    )
  }
}



export default Partner;
