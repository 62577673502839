'use strict';

import React, { Component } from 'react';
import { Switch, NavBar, Checkbox, Radio, Input, Tabs, TabBar, Badge } from 'antd-mobile';

import styles from './assets/index.module.css';

const print = function (value) {};
class DDS_lanhupage_0 extends Component {
  state = {};
  setValue(val) {}
  render() {
    return (
      <div className={`${styles.page} flex-col`}>
        <div className={`${styles['box_1']} flex-col`}>
          <div className={`${styles['group_1']} flex-row`}>
            <img
              className={`${styles['image_1']}`}
              src={
                require('./assets/img/SketchPng9f06d0db570db41de5c026ea08d5b9fd623f507cc4e13b72b7d9ec43ff08d10c.png')
              }
            />
            <span className={`${styles['text_1']}`}>首页</span>
            <span className={`${styles['text_2']}`}>公司简介</span>
            <span className={`${styles['text_3']}`}>主营业务</span>
            <span className={`${styles['text_4']}`}>产品方案</span>
            <span className={`${styles['text_5']}`}>合作伙伴</span>
            <span className={`${styles['text_6']}`}>营业网点</span>
            <span className={`${styles['text_7']}`}>&nbsp;人才招聘</span>
            <span className={`${styles['text_8']}`}>中/</span>
            <span className={`${styles['text_9']}`}>En</span>
            <img
              className={`${styles['label_1']}`}
              src={
                require('./assets/img/SketchPng891cf14de262b84ebfcf52ec76b81e4dfc293a2ca6a043b18cda43ce54425544.png')
              }
            />
            <span className={`${styles['text_10']}`}>400-880-5656</span>
          </div>
          <span className={`${styles['paragraph_1']}`}>
            使客户聚焦自身核心主业
            <br />
            更专注与高效实现商业成功
          </span>
        </div>
        <div className={`${styles['box_2']} flex-col`}>
          <div className={`${styles['text-wrapper_1']}`}>
            <span className={`${styles['text_11']}`}>产品方案</span>
            <span className={`${styles['text_12']}`}>&nbsp;&gt;</span>
            <span className={`${styles['text_13']}`}>解决方案&nbsp;&gt;</span>
            <span className={`${styles['text_14']}`}>泛金融&nbsp;</span>
          </div>
          <span className={`${styles['text_15']}`}>相关概况</span>
          <span className={`${styles['text_16']}`}>
            数字化时代的到来，对于业务场景和模式带来了机遇和挑战，金融机构的创新、敏捷、开放的能力建设，对核心交易系统、大数据平台及数据资产管理平台、业务共享能力提出了新的要求，分布式核心、数据中台、业务中台作为下一代的双中台数字化转型建设的技术方向，以业务创新及用户体验升级为目标，达到业务、架构、运维的精妙平衡。
          </span>
          <span className={`${styles['text_17']}`}>行业痛点</span>
          <div className={`${styles['list_1']} flex-row`}>
            <div className={`${styles['text-group_1-0']} flex-col`}>
              <span className={`${styles['text_18-0']}`}>管理方式粗放</span>
              <span className={`${styles['text_19-0']}`}>传统金融领域存在管理方式粗放，不严谨</span>
            </div>
            <div className={`${styles['text-group_1-1']} flex-col`}>
              <span className={`${styles['text_18-1']}`}>技术水平低下</span>
              <span className={`${styles['text_19-1']}`}>数字化水平低，人工效率低</span>
            </div>
            <div className={`${styles['text-group_1-2']} flex-col`}>
              <span className={`${styles['text_18-2']}`}>动能不足</span>
              <span className={`${styles['text_19-2']}`}>信息化建设滞后，阻挡大，维护成本高</span>
            </div>
            <div className={`${styles['text-group_1-3']} flex-col`}>
              <span className={`${styles['text_18-3']}`}>建设经费人才短缺</span>
              <span className={`${styles['text_19-3']}`}>人才信息等要素建设存在经费不足，无法支撑发展</span>
            </div>
          </div>
          <span className={`${styles['text_20']}`}>方案技术架构</span>
          <span className={`${styles['paragraph_2']}`}>
            申美信息提供包括OMS电商订单管理系统、TMS等多种高可靠高效管理软件
            <br />
            为企业客户定制化开发生产管理软件
          </span>
          <img
            className={`${styles['image_2']}`}
            src={
              require('./assets/img/SketchPng340f4255d0d6ea2e428e62473fab3c1518a5ff0c960d2137ebdb0ed4138cb7fc.png')
            }
          />
          <div className={`${styles['group_2']} flex-col`}>
            <span className={`${styles['text_21']}`}>方案优势</span>
            <div className={`${styles['list_2']} flex-row`}>
              <div className={`${styles['list-items_1-0']} flex-col`}>
                <img
                  className={`${styles['image_3-0']}`}
                  src={
                    require('./assets/img/SketchPng726472d4bfb831e1b08f21b668bed5fbfae53928f97f0e4197b3cfb216a9eadb.png')
                  }
                />
                <div className={`${styles['group_3-0']} flex-row`}>
                  <span className={`${styles['text_22-0']}`}>集成效率高</span>
                  <img
                    className={`${styles['thumbnail_4-0']}`}
                    src={
                      require('./assets/img/SketchPng451239fbc0d1255c54901b6c083c65f1ab96023cfa49d856c1b2e1b15e47e412.png')
                    }
                  />
                </div>
                <span className={`${styles['text_23-0']}`}>核心功能高度集成化，具有效率高，多任务处理能力</span>
              </div>
              <div className={`${styles['list-items_1-1']} flex-col`}>
                <img
                  className={`${styles['image_3-1']}`}
                  src={
                    require('./assets/img/SketchPng86d16499d181f06838c75aed69d484046c9734aa26e534ae5d2cd2546b622c69.png')
                  }
                />
                <div className={`${styles['group_3-1']} flex-row`}>
                  <span className={`${styles['text_22-1']}`}>专业精准匹配</span>
                  <img
                    className={`${styles['thumbnail_3-1']}`}
                    src={
                      require('./assets/img/SketchPng451239fbc0d1255c54901b6c083c65f1ab96023cfa49d856c1b2e1b15e47e412.png')
                    }
                  />
                </div>
                <span className={`${styles['text_23-1']}`}>核心模块精准匹配行业需求，痛点各个击破</span>
              </div>
              <div className={`${styles['list-items_1-2']} flex-col`}>
                <img
                  className={`${styles['image_3-2']}`}
                  src={
                    require('./assets/img/SketchPng2c0064d8f0a22468f73ccac788e91889815459c6262100c1f535cb0b720f3d2f.png')
                  }
                />
                <div className={`${styles['group_3-2']} flex-row`}>
                  <span className={`${styles['text_22-2']}`}>高可靠、低维护成本</span>
                  <img
                    className={`${styles['thumbnail_2-2']}`}
                    src={
                      require('./assets/img/SketchPng451239fbc0d1255c54901b6c083c65f1ab96023cfa49d856c1b2e1b15e47e412.png')
                    }
                  />
                </div>
                <span className={`${styles['text_23-2']}`}>高可靠性，采用单元化架构，低维护成本</span>
              </div>
              <div className={`${styles['list-items_1-3']} flex-col`}>
                <img
                  className={`${styles['image_3-3']}`}
                  src={
                    require('./assets/img/SketchPngd3ec0734e4da47a29f11dedb49359f136968699d2a6037da0cbc11512aa239b5.png')
                  }
                />
                <div className={`${styles['group_3-3']} flex-row`}>
                  <span className={`${styles['text_22-3']}`}>可定制、可拓展</span>
                  <img
                    className={`${styles['thumbnail_1-3']}`}
                    src={
                      require('./assets/img/SketchPng451239fbc0d1255c54901b6c083c65f1ab96023cfa49d856c1b2e1b15e47e412.png')
                    }
                  />
                </div>
                <span className={`${styles['text_23-3']}`}>可根据用户需求定制开发扩展，最大匹配客户需求</span>
              </div>
            </div>
          </div>
          <span className={`${styles['text_24']}`}>业务覆盖</span>
          <img
            className={`${styles['image_4']}`}
            src={
              require('./assets/img/SketchPng9b9407ae3d4aa55d3b6f2b308c33b23c99b7921c50bcfc003fd1479e92a76d22.png')
            }
          />
          <div className={`${styles['group_4']} flex-col`}>
            <div className={`${styles['text-wrapper_2']} flex-row`}>
              <span className={`${styles['text_25']}`}>相关案例</span>
            </div>
            <div className={`${styles['group_5']} flex-row`}>
              <img
                className={`${styles['label_2']}`}
                src={
                  require('./assets/img/SketchPng54c9af0969c0994fdea6a9b3d6d85130a415bdcea9026f6f7f957c5cb8ccd77a.png')
                }
              />
              <div className={`${styles['list_3']} flex-row`}>
                <div className={`${styles['image-text_1-0']} flex-col`}>
                  <div className={`${styles['block_1-0']} flex-col`}>
                    <div className={`${styles['image-wrapper_1-0']} flex-col`}>
                      <img
                        className={`${styles['label_3-0']}`}
                        src={
                          require('./assets/img/SketchPng05da77fcc54756473d83ad85d4a76be9b404159f5010e77a5f4b618dd2450d31.png')
                        }
                      />
                    </div>
                  </div>
                  <div className={`${styles['text-group_2-0']} flex-col justify-between`}>
                    <span className={`${styles['text_26-0']}`}>中银建信CPM管理系统</span>
                    <span className={`${styles['text_27-0']}`}>核心功能高度集成化，具有效率高，多任务处理能力</span>
                  </div>
                </div>
                <div className={`${styles['image-text_1-1']} flex-col`}>
                  <div className={`${styles['block_1-1']} flex-col`}>
                    <div className={`${styles['image-wrapper_1-1']} flex-col`}>
                      <img
                        className={`${styles['label_3-1']}`}
                        src={
                          require('./assets/img/SketchPngf1e11a74c2954ae44db54ef170ad456e87b7c55ebce084eb792f77043829bb8b.png')
                        }
                      />
                    </div>
                  </div>
                  <div className={`${styles['text-group_2-1']} flex-col justify-between`}>
                    <span className={`${styles['text_26-1']}`}>平安银行预算管理</span>
                    <span className={`${styles['text_27-1']}`}>
                      工商银行以申美函数计算产品为核心打造了Serverless&nbsp;函数计算平台技术架构2.0。
                    </span>
                  </div>
                </div>
                <div className={`${styles['image-text_1-2']} flex-col`}>
                  <div className={`${styles['block_1-2']} flex-col`}>
                    <div className={`${styles['image-wrapper_1-2']} flex-col`}>
                      <img
                        className={`${styles['label_3-2']}`}
                        src={
                          require('./assets/img/SketchPngb3ab42d1d191fe30f7480b7f3fa43e62f77126f3c3ba8ea7e83fe17b84a1cecd.png')
                        }
                      />
                    </div>
                  </div>
                  <div className={`${styles['text-group_2-2']} flex-col justify-between`}>
                    <span className={`${styles['text_26-2']}`}>上海银行SaaS应用系统</span>
                    <span className={`${styles['text_27-2']}`}>
                      工商银行以申美函数计算产品为核心打造了Serverless&nbsp;函数计算平台技术架构2.0。
                    </span>
                  </div>
                </div>
                <div className={`${styles['image-text_1-3']} flex-col`}>
                  <div className={`${styles['block_1-3']} flex-col`}>
                    <div className={`${styles['image-wrapper_1-3']} flex-col`}>
                      <img
                        className={`${styles['label_3-3']}`}
                        src={
                          require('./assets/img/SketchPngfbee47e64a5d03064431b9f90f36f56420b57177e4ff65db039a8399f5c26da2.png')
                        }
                      />
                    </div>
                  </div>
                  <div className={`${styles['text-group_2-3']} flex-col justify-between`}>
                    <span className={`${styles['text_26-3']}`}>苏宁网银理财通</span>
                    <span className={`${styles['text_27-3']}`}>
                      工商银行以申美函数计算产品为核心打造了Serverless&nbsp;函数计算平台技术架构2.0。
                    </span>
                  </div>
                </div>
              </div>
              <img
                className={`${styles['label_4']}`}
                src={
                  require('./assets/img/SketchPng3b5b0d3ec93476e5483122ffdf88ca3d350abb01f23620da02eb212fdfbc7df8.png')
                }
              />
            </div>
          </div>
          <div className={`${styles['group_6']} flex-col`}>
            <span className={`${styles['paragraph_3']}`}>
              丰富的应用场景和行业解决方案，为企业赋能、让生产
              <br />
              高效、降本增效
            </span>
            <div className={`${styles['image-text_2']} flex-row justify-between`}>
              <div className={`${styles['section_1']} flex-row`}>
                <div className={`${styles['image-text_3']} flex-row justify-between`}>
                  <span className={`${styles['text-group_3']}`}>电话咨询</span>
                  <img
                    className={`${styles['thumbnail_5']}`}
                    src={
                      require('./assets/img/SketchPng35c0f2e8340648d41a261fbf3a14647c23e57cd7108e01120254985b00721c67.png')
                    }
                  />
                </div>
                <div className={`${styles['image-text_4']} flex-row justify-between`}>
                  <span className={`${styles['text-group_3']}`}>电话咨询</span>
                  <img
                    className={`${styles['thumbnail_5']}`}
                    src={
                      require('./assets/img/SketchPng35c0f2e8340648d41a261fbf3a14647c23e57cd7108e01120254985b00721c67.png')
                    }
                  />
                </div>
              </div>
              <span className={`${styles['text-group_4']}`}>400-880-5656</span>
            </div>
            <img
              className={`${styles['image_5']}`}
              src={
                require('./assets/img/SketchPng0ee43f01bd13f6ba21297c72b1908216c8d8f3811421a22e7f0638fc11277f0e.png')
              }
            />
          </div>
          <div className={`${styles['group_7']} flex-col`}>
            <div className={`${styles['group_8']} flex-row`}>
              <div className={`${styles['section_2']} flex-col justify-between`}>
                <span className={`${styles['text_28']}`}>IT综合服务</span>
                <div className={`${styles['text-group_5']} flex-col justify-between`}>
                  <span className={`${styles['text_29']}`}>ITO人力</span>
                  <span className={`${styles['text_30']}`}>ITO项目</span>
                  <span className={`${styles['text_31']}`}>ITO测试</span>
                </div>
              </div>
              <div className={`${styles['section_3']} flex-col justify-between`}>
                <span className={`${styles['text_32']}`}>企业服务</span>
                <div className={`${styles['text-group_6']} flex-col justify-between`}>
                  <span className={`${styles['text_33']}`}>BPO服务</span>
                  <span className={`${styles['text_34']}`}>NDC&amp;ODC</span>
                  <span className={`${styles['text_35']}`}>知识产权服务</span>
                </div>
              </div>
              <div className={`${styles['section_4']} flex-col justify-between`}>
                <span className={`${styles['text_36']}`}>关于申美</span>
                <div className={`${styles['text-group_7']} flex-col justify-between`}>
                  <span className={`${styles['text_37']}`}>企业简介</span>
                  <span className={`${styles['text_38']}`}>招聘中心</span>
                  <span className={`${styles['text_39']}`}>合作伙伴</span>
                </div>
              </div>
              <div className={`${styles['section_5']} flex-col justify-between`}>
                <div className={`${styles['image-text_5']} flex-col justify-between`}>
                  <img
                    className={`${styles['image_6']}`}
                    src={
                      require('./assets/img/SketchPngc6cbbd9032162aa64557e58a63aa4ca3f06762f357621999bb52d7507235eed5.png')
                    }
                  />
                  <span className={`${styles['text-group_8']}`}>上海申美信息科技有限公司</span>
                </div>
                <span className={`${styles['paragraph_4']}`}>
                  地址：上海市普陀区大渡河路388弄5号楼406室
                  <br />
                  电话：13391356946
                  <br />
                  email：surmeta&#64;surmeta.com
                </span>
              </div>
            </div>
            <div className={`${styles['group_9']} flex-row justify-between`}>
              <div className={`${styles['image-text_6']} flex-col justify-between`}>
                <div className={`${styles['box_3']} flex-col`} />
                <span className={`${styles['text-group_9']}`}>官方微信号</span>
              </div>
              <div className={`${styles['image-text_7']} flex-col justify-between`}>
                <div className={`${styles['box_4']} flex-col`} />
                <span className={`${styles['text-group_10']}`}>抖音视频号</span>
              </div>
            </div>
          </div>
        </div>
        <div className={`${styles['box_5']} flex-col`}>
          <div className={`${styles['text-wrapper_3']}`}>
            <span className={`${styles['paragraph_5']}`}>
              沪ICP证0876125号&nbsp;&nbsp;&nbsp;沪ICP备987788号&nbsp;
              <br />
              copyright©
            </span>
            <span className={`${styles['text_40']}`}>SurMeta&nbsp;</span>
            <span className={`${styles['text_41']}`}>法律声明</span>
          </div>
        </div>
      </div>
    );
  }
}
export default DDS_lanhupage_0;
