'use strict';

import React, { Component } from 'react';
import {Nav} from '../component/nav';
import {Footer} from '../component/footer';

import './recruit.css';


class Recruit extends Component {


  render() {
    return (
      <div className='page-main'>
        <Nav curr="人才招聘"></Nav>

         <div className='app-recruit-banner'>
            <div className='app-recruit-banner-title'>
                双向奔赴  一起成长
            </div>
        </div>

        <div style={{width:'100%',height:20}}></div>  

        <RecruitNav title='产品'  type='cp'/>
        <div className='app-recruit-flex'>
            <div className='app-recruit-content-left'>
                <div className='app-recruit-item' iscurr='true'>B端产品经理</div>
                <div className='app-recruit-item'>电商产品经理</div>
            </div>
            <div className='app-recruit-content'>
                <div className='app-recruit-content-title'>工作职责</div>
                <div >
                    1 与分行一起拜访客户，挖堀客户需求，高效对话并理解客户需求，并围绕客户需求助力分行业务制定‘科技＋金融’解决方素，对客户提出的科技需求进行远程支持或现场指导。
                    <br/>
                    2 梳理业务流程、提升分行需求分析质量，撰写高质量需求说明书，提升与总行产品部门沟通效率，快速推进需求澄清等事项，
                    <br/>
                    3 跟踪总分行在系统和科技产品需求实施进度，缩短问题解決路径，及时推进项目落地。
                </div>
                <div className='app-recruit-content-title'>岗位要求</div>
                 <div >
                    1 与分行一起拜访客户，挖堀客户需求，高效对话并理解客户需求，并围绕客户需求助力分行业务制定‘科技＋金融’解决方素，对客户提出的科技需求进行远程支持或现场指导。
                </div>
            </div>
        </div>

        <RecruitNav title='开发'  type='kf'/>
        <div className='app-recruit-flex'>
            <div className='app-recruit-content-left'>
                <div className='app-recruit-item' iscurr='true'>Java 开发工程师</div>
                <div className='app-recruit-item'>大数据工程师</div>
                <div className='app-recruit-item'>前端开发</div>
            </div>
            <div className='app-recruit-content'>
                <div className='app-recruit-content-title'>工作职责</div>
                <div >
                    1 与分行一起拜访客户，挖堀客户需求，高效对话并理解客户需求，并围绕客户需求助力分行业务制定‘科技＋金融’解决方素，对客户提出的科技需求进行远程支持或现场指导。
                    <br/>
                    2 梳理业务流程、提升分行需求分析质量，撰写高质量需求说明书，提升与总行产品部门沟通效率，快速推进需求澄清等事项，
                    <br/>
                    3 跟踪总分行在系统和科技产品需求实施进度，缩短问题解決路径，及时推进项目落地。
                </div>
                <div className='app-recruit-content-title'>岗位要求</div>
                 <div >
                    1 与分行一起拜访客户，挖堀客户需求，高效对话并理解客户需求，并围绕客户需求助力分行业务制定‘科技＋金融’解决方素，对客户提出的科技需求进行远程支持或现场指导。
                </div>
            </div>
        </div>

        <RecruitNav title='运维'  type='yw'/>
         <div className='app-recruit-flex'>
            <div className='app-recruit-content-left'>
                <div className='app-recruit-item' iscurr='true'>大数据标注</div>
                <div className='app-recruit-item'>直播中控</div>
                <div className='app-recruit-item'>硬件运维</div>
            </div>
            <div className='app-recruit-content'>
                <div className='app-recruit-content-title'>工作职责</div>
                <div >
                    1 与分行一起拜访客户，挖堀客户需求，高效对话并理解客户需求，并围绕客户需求助力分行业务制定‘科技＋金融’解决方素，对客户提出的科技需求进行远程支持或现场指导。
                    <br/>
                    2 梳理业务流程、提升分行需求分析质量，撰写高质量需求说明书，提升与总行产品部门沟通效率，快速推进需求澄清等事项，
                    <br/>
                    3 跟踪总分行在系统和科技产品需求实施进度，缩短问题解決路径，及时推进项目落地。
                </div>
                <div className='app-recruit-content-title'>岗位要求</div>
                 <div >
                    1 与分行一起拜访客户，挖堀客户需求，高效对话并理解客户需求，并围绕客户需求助力分行业务制定‘科技＋金融’解决方素，对客户提出的科技需求进行远程支持或现场指导。
                </div>
            </div>
        </div>

        <RecruitNav title='设计'  type='sj'/>
        <div className='app-recruit-flex'>
            <div className='app-recruit-content-left'>
                <div className='app-recruit-item' iscurr='true'>B端页面设计师</div>
                <div className='app-recruit-item'>C4D设计师</div>
                <div className='app-recruit-item'>用户体验设计</div>
            </div>
            <div className='app-recruit-content'>
                <div className='app-recruit-content-title'>工作职责</div>
                <div >
                    1 与分行一起拜访客户，挖堀客户需求，高效对话并理解客户需求，并围绕客户需求助力分行业务制定‘科技＋金融’解决方素，对客户提出的科技需求进行远程支持或现场指导。
                    <br/>
                    2 梳理业务流程、提升分行需求分析质量，撰写高质量需求说明书，提升与总行产品部门沟通效率，快速推进需求澄清等事项，
                    <br/>
                    3 跟踪总分行在系统和科技产品需求实施进度，缩短问题解決路径，及时推进项目落地。
                </div>
                <div className='app-recruit-content-title'>岗位要求</div>
                 <div >
                    1 与分行一起拜访客户，挖堀客户需求，高效对话并理解客户需求，并围绕客户需求助力分行业务制定‘科技＋金融’解决方素，对客户提出的科技需求进行远程支持或现场指导。
                </div>
            </div>
        </div>

        <RecruitNav title='服务'  type='fw'/>
        <div className='app-recruit-flex'>
            <div className='app-recruit-content-left'>
                <div className='app-recruit-item' iscurr='true'>前台接待</div>
                <div className='app-recruit-item'>客服</div>
            </div>
            <div className='app-recruit-content'>
                <div className='app-recruit-content-title'>工作职责</div>
                <div >
                    1 与分行一起拜访客户，挖堀客户需求，高效对话并理解客户需求，并围绕客户需求助力分行业务制定‘科技＋金融’解决方素，对客户提出的科技需求进行远程支持或现场指导。
                    <br/>
                    2 梳理业务流程、提升分行需求分析质量，撰写高质量需求说明书，提升与总行产品部门沟通效率，快速推进需求澄清等事项，
                    <br/>
                    3 跟踪总分行在系统和科技产品需求实施进度，缩短问题解決路径，及时推进项目落地。
                </div>
                <div className='app-recruit-content-title'>岗位要求</div>
                 <div >
                    1 与分行一起拜访客户，挖堀客户需求，高效对话并理解客户需求，并围绕客户需求助力分行业务制定‘科技＋金融’解决方素，对客户提出的科技需求进行远程支持或现场指导。
                </div>
            </div>
        </div>




           

        <div style={{width:'100%',height:100}}></div>         
       
        <Footer/>
 
       </div>
    )
  }
}



const RecruitNav = (props) =>{
    return (<div className='app-flex' style={{marginTop:10 , marginBottom:20}}>
            <div className='app-recruit-nav' data-type={props.type}>
                <span>{'>'}</span>{props.title}
            </div>
    </div>)
}




export default Recruit;
