'use strict';

import React, { Component } from 'react';
import { Switch, NavBar, Checkbox, Radio, Input, Tabs, TabBar, Badge } from 'antd-mobile';

import styles from './assets/index.module.css';

const print = function (value) {};
class DDS_lanhupage_0 extends Component {
  state = {};
  setValue(val) {}
  render() {
    return (
      <div className={`${styles.page} flex-col`}>
        <div className={`${styles['block_1']} flex-col justify-between`}>
          <div className={`${styles['box_1']} flex-col`}>
            <div className={`${styles['box_2']} flex-row`}>
              <img
                className={`${styles['image_1']}`}
                src={
                  require('./assets/img/SketchPng9f06d0db570db41de5c026ea08d5b9fd623f507cc4e13b72b7d9ec43ff08d10c.png')
                }
              />
              <span className={`${styles['text_1']}`}>首页</span>
              <span className={`${styles['text_2']}`}>公司简介</span>
              <span className={`${styles['text_3']}`}>主营业务</span>
              <span className={`${styles['text_4']}`}>产品方案</span>
              <span className={`${styles['text_5']}`}>合作伙伴</span>
              <span className={`${styles['text_6']}`}>营业网点</span>
              <span className={`${styles['text_7']}`}>&nbsp;人才招聘</span>
              <span className={`${styles['text_8']}`}>中/</span>
              <span className={`${styles['text_9']}`}>En</span>
              <img
                className={`${styles['label_1']}`}
                src={
                  require('./assets/img/SketchPng891cf14de262b84ebfcf52ec76b81e4dfc293a2ca6a043b18cda43ce54425544.png')
                }
              />
              <span className={`${styles['text_10']}`}>400-880-5656</span>
            </div>
            <span className={`${styles['paragraph_1']}`}>
              使客户聚焦自身核心主业
              <br />
              更专注与高效实现商业成功
            </span>
          </div>
          <div className={`${styles['box_3']} flex-col justify-end`}>
            <div className={`${styles['text-wrapper_1']} flex-row justify-between`}>
              <span className={`${styles['text_11']}`}>信息技术外包</span>
              <span className={`${styles['text_12']}`}>信息技术生态业务</span>
              <span className={`${styles['text_13']}`}>知识流程外包</span>
              <span className={`${styles['text_14']}`}>企业综合服务</span>
              <span className={`${styles['text_15']}`}>人力资源外包</span>
              <span className={`${styles['text_16']}`}>商务流程外包</span>
            </div>
            <div className={`${styles['box_4']} flex-row`}>
              <div className={`${styles['group_1']} flex-col`} />
            </div>
          </div>
        </div>
        <div className={`${styles['block_2']} flex-col`}>
          <img
            className={`${styles['image_2']}`}
            src={
              require('./assets/img/SketchPnge5bede092dfa68525abcc96b679d45ea12b7556f0c4486cfc18e01d11dea3f7c.png')
            }
          />
          <span className={`${styles['text_17']}`}>服务概况</span>
          <img
            className={`${styles['image_3']}`}
            src={
              require('./assets/img/SketchPngb7565c2a0bf16563288b784db875592136146c31ac32867ed742c2e769bc921a.png')
            }
          />
          <div className={`${styles['text-group_1']} flex-col justify-between`}>
            <span className={`${styles['paragraph_2']}`}>
              金融行业正在经历快速增长，而且随着外界环境的改变，也在持续不断的发生变化和变革。为了更好的应对变革，金融机构需要重新审视当前的战略，重新评价系统和流程中的商业模式，以保持活力和竞争力。
              <br />
              &nbsp;&nbsp;&nbsp;申美信息以深厚的行业知识，为客户提供多样化的专业服务，帮助客户改善与用户之间的互动与交流，加强工作流程管理和商务智能，并简化传统应用以支持实时、灵活的业务流程，享受效率、收益和创新。同时，使客户能更好的管理风险，提高产品的性能和快速实现业务转型。
            </span>
            <span className={`${styles['text_18']}`}>信息技术外包服务(ITO)</span>
          </div>
          <img
            className={`${styles['image_4']}`}
            src={
              require('./assets/img/SketchPngb7565c2a0bf16563288b784db875592136146c31ac32867ed742c2e769bc921a.png')
            }
          />
          <div className={`${styles['block_3']} flex-row`}>
            <div className={`${styles['image-text_1']} flex-row justify-between`}>
              <img
                className={`${styles['thumbnail_1']}`}
                src={
                  require('./assets/img/SketchPng8d9fd0b4b7e251e0b23f0fd481f881efbbeb385e56b980b22a7ec4e60cf456dc.png')
                }
              />
              <span className={`${styles['text-group_2']}`}>人力外包服务</span>
            </div>
            <div className={`${styles['box_5']} flex-col`} />
          </div>
          <div className={`${styles['block_4']} flex-row justify-between`}>
            <div className={`${styles['text-wrapper_2']} flex-col`}>
              <span className={`${styles['text_19']}`}>开发</span>
            </div>
            <div className={`${styles['text-wrapper_3']} flex-col`}>
              <span className={`${styles['text_20']}`}>测试</span>
            </div>
            <div className={`${styles['text-wrapper_4']} flex-col`}>
              <span className={`${styles['text_21']}`}>前端</span>
            </div>
          </div>
          <div className={`${styles['block_5']} flex-row justify-between`}>
            <div className={`${styles['text-wrapper_5']} flex-col`}>
              <span className={`${styles['text_22']}`}>前端</span>
            </div>
            <div className={`${styles['text-wrapper_6']} flex-col`}>
              <span className={`${styles['text_23']}`}>运维</span>
            </div>
            <div className={`${styles['text-wrapper_7']} flex-col`}>
              <span className={`${styles['text_24']}`}>设计</span>
            </div>
          </div>
          <div className={`${styles['block_6']} flex-row justify-between`}>
            <div className={`${styles['text-wrapper_8']} flex-col`}>
              <span className={`${styles['text_25']}`}>商务</span>
            </div>
            <div className={`${styles['text-wrapper_9']} flex-col`}>
              <span className={`${styles['text_26']}`}>管理</span>
            </div>
            <div className={`${styles['text-wrapper_10']} flex-col`}>
              <span className={`${styles['text_27']}`}>…</span>
            </div>
          </div>
          <div className={`${styles['block_7']} flex-row`}>
            <div className={`${styles['image-text_2']} flex-row justify-between`}>
              <img
                className={`${styles['thumbnail_2']}`}
                src={
                  require('./assets/img/SketchPng8d9fd0b4b7e251e0b23f0fd481f881efbbeb385e56b980b22a7ec4e60cf456dc.png')
                }
              />
              <span className={`${styles['text-group_3']}`}>项目外包服务</span>
            </div>
            <div className={`${styles['box_6']} flex-col`} />
          </div>
          <div className={`${styles['block_8']} flex-row justify-between`}>
            <div className={`${styles['text-wrapper_11']} flex-col`}>
              <span className={`${styles['text_28']}`}>开发</span>
            </div>
            <div className={`${styles['text-wrapper_12']} flex-col`}>
              <span className={`${styles['text_29']}`}>测试</span>
            </div>
            <div className={`${styles['text-wrapper_13']} flex-col`}>
              <span className={`${styles['text_30']}`}>运维</span>
            </div>
          </div>
          <div className={`${styles['block_9']} flex-row justify-between`}>
            <div className={`${styles['box_7']} flex-col`}>
              <div className={`${styles['box_8']} flex-row justify-between`}>
                <div className={`${styles['text-wrapper_14']} flex-col`}>
                  <span className={`${styles['text_31']}`}>集成</span>
                </div>
                <div className={`${styles['text-wrapper_15']} flex-col`}>
                  <span className={`${styles['text_32']}`}>实施</span>
                </div>
                <div className={`${styles['text-wrapper_16']} flex-col`}>
                  <span className={`${styles['text_33']}`}>综合</span>
                </div>
              </div>
              <div className={`${styles['box_9']} flex-row`}>
                <div className={`${styles['image-text_3']} flex-row justify-between`}>
                  <img
                    className={`${styles['thumbnail_3']}`}
                    src={
                      require('./assets/img/SketchPng8d9fd0b4b7e251e0b23f0fd481f881efbbeb385e56b980b22a7ec4e60cf456dc.png')
                    }
                  />
                  <span className={`${styles['text-group_4']}`}>研发中心服务</span>
                </div>
                <div className={`${styles['box_10']} flex-col`} />
              </div>
              <div className={`${styles['box_11']} flex-row justify-between`}>
                <div className={`${styles['text-wrapper_17']} flex-col`}>
                  <span className={`${styles['text_34']}`}>驻场</span>
                </div>
                <div className={`${styles['text-wrapper_18']} flex-col`}>
                  <span className={`${styles['text_35']}`}>近岸</span>
                </div>
                <div className={`${styles['text-wrapper_19']} flex-col`}>
                  <span className={`${styles['text_36']}`}>离岸</span>
                </div>
              </div>
            </div>
            <div className={`${styles['box_12']} flex-col`}>
              <div className={`${styles['image-wrapper_1']} flex-col`}>
                <img
                  className={`${styles['label_2']}`}
                  src={
                    require('./assets/img/SketchPng4865c29fdca52372ca94024eca78ded09ad4c881953456b8ac06dbd13605e750.png')
                  }
                />
              </div>
              <div className={`${styles['image-wrapper_2']} flex-col`}>
                <img
                  className={`${styles['label_3']}`}
                  src={
                    require('./assets/img/SketchPng3cf5aed6be634876fa86d0106a713af639ea10ac425b846b3cad51f4294ea66a.png')
                  }
                />
              </div>
              <div className={`${styles['section_1']} flex-col`}>
                <div className={`${styles['image-wrapper_3']} flex-col`}>
                  <img
                    className={`${styles['image_5']}`}
                    src={
                      require('./assets/img/SketchPng6723eac3ac83c63bda95850749a39a9cc8fd5ab06b563ab15cda1dad0e971329.png')
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <span className={`${styles['text_37']}`}>信息技术生态业务(ITEB)&nbsp;IT&nbsp;Ecoligical&nbsp;business</span>
          <img
            className={`${styles['image_6']}`}
            src={
              require('./assets/img/SketchPngb7565c2a0bf16563288b784db875592136146c31ac32867ed742c2e769bc921a.png')
            }
          />
          <div className={`${styles['block_10']} flex-row justify-between`}>
            <div className={`${styles['text-wrapper_20']} flex-col`}>
              <span className={`${styles['text_38']}`}>产品代理</span>
            </div>
            <div className={`${styles['text-wrapper_21']} flex-col`}>
              <span className={`${styles['text_39']}`}>产品实施</span>
            </div>
            <div className={`${styles['text-wrapper_22']} flex-col`}>
              <span className={`${styles['text_40']}`}>解决方案</span>
            </div>
          </div>
          <div className={`${styles['block_11']} flex-row justify-between`}>
            <div className={`${styles['text-wrapper_23']} flex-col`}>
              <span className={`${styles['text_41']}`}>培训认证</span>
            </div>
            <div className={`${styles['text-wrapper_24']} flex-col`}>
              <span className={`${styles['text_42']}`}>…</span>
            </div>
          </div>
          <span className={`${styles['text_43']}`}>知识流程外包服务(KPO)</span>
          <img
            className={`${styles['image_7']}`}
            src={
              require('./assets/img/SketchPngb7565c2a0bf16563288b784db875592136146c31ac32867ed742c2e769bc921a.png')
            }
          />
          <div className={`${styles['block_12']} flex-row justify-between`}>
            <div className={`${styles['text-wrapper_25']} flex-col`}>
              <span className={`${styles['text_44']}`}>知识管理</span>
            </div>
            <div className={`${styles['text-wrapper_26']} flex-col`}>
              <span className={`${styles['text_45']}`}>战略咨询</span>
            </div>
            <div className={`${styles['text-wrapper_27']} flex-col`}>
              <span className={`${styles['text_46']}`}>技术咨询</span>
            </div>
          </div>
          <div className={`${styles['block_13']} flex-row justify-between`}>
            <div className={`${styles['text-wrapper_28']} flex-col`}>
              <span className={`${styles['text_47']}`}>业务咨询</span>
            </div>
            <div className={`${styles['text-wrapper_29']} flex-col`}>
              <span className={`${styles['text_48']}`}>管理咨询</span>
            </div>
          </div>
          <span className={`${styles['text_49']}`}>企业综合服务(ECS)&nbsp;Enterprise&nbsp;Comprehensive&nbsp;Service</span>
          <img
            className={`${styles['image_8']}`}
            src={
              require('./assets/img/SketchPngb7565c2a0bf16563288b784db875592136146c31ac32867ed742c2e769bc921a.png')
            }
          />
          <div className={`${styles['block_14']} flex-row justify-between`}>
            <div className={`${styles['text-wrapper_30']} flex-col`}>
              <span className={`${styles['text_50']}`}>市场推广</span>
            </div>
            <div className={`${styles['text-wrapper_31']} flex-col`}>
              <span className={`${styles['text_51']}`}>知识产权</span>
            </div>
            <div className={`${styles['text-wrapper_32']} flex-col`}>
              <span className={`${styles['text_52']}`}>展会峰会</span>
            </div>
          </div>
          <div className={`${styles['block_15']} flex-row justify-between`}>
            <div className={`${styles['text-wrapper_33']} flex-col`}>
              <span className={`${styles['text_53']}`}>产业协同</span>
            </div>
            <div className={`${styles['text-wrapper_34']} flex-col`}>
              <span className={`${styles['text_54']}`}>…</span>
            </div>
          </div>
          <span className={`${styles['text_55']}`}>人力资源外包服务(HRO)</span>
          <img
            className={`${styles['image_9']}`}
            src={
              require('./assets/img/SketchPngb7565c2a0bf16563288b784db875592136146c31ac32867ed742c2e769bc921a.png')
            }
          />
          <div className={`${styles['block_16']} flex-row justify-between`}>
            <div className={`${styles['text-wrapper_35']} flex-col`}>
              <span className={`${styles['text_56']}`}>开发</span>
            </div>
            <div className={`${styles['text-wrapper_36']} flex-col`}>
              <span className={`${styles['text_57']}`}>测试</span>
            </div>
            <div className={`${styles['text-wrapper_37']} flex-col`}>
              <span className={`${styles['text_58']}`}>前端</span>
            </div>
          </div>
          <div className={`${styles['block_17']} flex-row justify-between`}>
            <div className={`${styles['text-wrapper_38']} flex-col`}>
              <span className={`${styles['text_59']}`}>前端</span>
            </div>
            <div className={`${styles['text-wrapper_39']} flex-col`}>
              <span className={`${styles['text_60']}`}>运维</span>
            </div>
            <div className={`${styles['text-wrapper_40']} flex-col`}>
              <span className={`${styles['text_61']}`}>设计</span>
            </div>
          </div>
          <div className={`${styles['block_18']} flex-row justify-between`}>
            <div className={`${styles['text-wrapper_41']} flex-col`}>
              <span className={`${styles['text_62']}`}>商务</span>
            </div>
            <div className={`${styles['text-wrapper_42']} flex-col`}>
              <span className={`${styles['text_63']}`}>管理</span>
            </div>
            <div className={`${styles['text-wrapper_43']} flex-col`}>
              <span className={`${styles['text_64']}`}>客服</span>
            </div>
          </div>
          <span className={`${styles['text_65']}`}>商务流程外包服务(BPO)</span>
          <img
            className={`${styles['image_10']}`}
            src={
              require('./assets/img/SketchPngb7565c2a0bf16563288b784db875592136146c31ac32867ed742c2e769bc921a.png')
            }
          />
          <div className={`${styles['block_19']} flex-row justify-between`}>
            <div className={`${styles['text-wrapper_44']} flex-col`}>
              <span className={`${styles['text_66']}`}>内容服务</span>
            </div>
            <div className={`${styles['text-wrapper_45']} flex-col`}>
              <span className={`${styles['text_67']}`}>基础建设</span>
            </div>
            <div className={`${styles['text-wrapper_46']} flex-col`}>
              <span className={`${styles['text_68']}`}>技术运营</span>
            </div>
          </div>
          <div className={`${styles['block_20']} flex-row justify-between`}>
            <div className={`${styles['text-wrapper_47']} flex-col`}>
              <span className={`${styles['text_69']}`}>数据服务</span>
            </div>
            <div className={`${styles['text-wrapper_48']} flex-col`}>
              <span className={`${styles['text_70']}`}>人力资源外包</span>
            </div>
            <div className={`${styles['text-wrapper_49']} flex-col`}>
              <span className={`${styles['text_71']}`}>客服中心</span>
            </div>
          </div>
          <div className={`${styles['block_21']} flex-col`}>
            <span className={`${styles['paragraph_3']}`}>
              丰富的应用场景和行业解决方案，为企业赋能、让生产
              <br />
              高效、降本增效
            </span>
            <div className={`${styles['image-text_4']} flex-row justify-between`}>
              <div className={`${styles['section_2']} flex-row`}>
                <div className={`${styles['image-text_5']} flex-row justify-between`}>
                  <span className={`${styles['text-group_5']}`}>电话咨询</span>
                  <img
                    className={`${styles['thumbnail_4']}`}
                    src={
                      require('./assets/img/SketchPng35c0f2e8340648d41a261fbf3a14647c23e57cd7108e01120254985b00721c67.png')
                    }
                  />
                </div>
                <div className={`${styles['image-text_6']} flex-row justify-between`}>
                  <span className={`${styles['text-group_5']}`}>电话咨询</span>
                  <img
                    className={`${styles['thumbnail_4']}`}
                    src={
                      require('./assets/img/SketchPng35c0f2e8340648d41a261fbf3a14647c23e57cd7108e01120254985b00721c67.png')
                    }
                  />
                </div>
              </div>
              <span className={`${styles['text-group_6']}`}>400-880-5656</span>
            </div>
            <img
              className={`${styles['image_11']}`}
              src={
                require('./assets/img/SketchPng0ee43f01bd13f6ba21297c72b1908216c8d8f3811421a22e7f0638fc11277f0e.png')
              }
            />
          </div>
          <div className={`${styles['block_22']} flex-col`}>
            <div className={`${styles['box_13']} flex-row`}>
              {/* <div className={`${styles['block_23']} flex-col justify-between`}>
                <span className={`${styles['text_72']}`}>IT综合服务</span>
                <div className={`${styles['text-group_7']} flex-col justify-between`}>
                  <span className={`${styles['text_73']}`}>ITO人力</span>
                  <span className={`${styles['text_74']}`}>ITO项目</span>
                  <span className={`${styles['text_75']}`}>ITO测试</span>
                </div>
              </div>
              <div className={`${styles['block_24']} flex-col justify-between`}>
                <span className={`${styles['text_76']}`}>企业服务</span>
                <div className={`${styles['text-group_8']} flex-col justify-between`}>
                  <span className={`${styles['text_77']}`}>BPO服务</span>
                  <span className={`${styles['text_78']}`}>NDC&amp;ODC</span>
                  <span className={`${styles['text_79']}`}>知识产权服务</span>
                </div>
              </div>
              <div className={`${styles['block_25']} flex-col justify-between`}>
                <span className={`${styles['text_80']}`}>关于申美</span>
                <div className={`${styles['text-group_9']} flex-col justify-between`}>
                  <span className={`${styles['text_81']}`}>企业简介</span>
                  <span className={`${styles['text_82']}`}>招聘中心</span>
                  <span className={`${styles['text_83']}`}>合作伙伴</span>
                </div>
              </div> */}
              <div className={`${styles['block_26']} flex-col justify-between`}>
                <div className={`${styles['image-text_7']} flex-col justify-between`}>
                  <img
                    className={`${styles['image_12']}`}
                    src={
                      require('./assets/img/SketchPngc6cbbd9032162aa64557e58a63aa4ca3f06762f357621999bb52d7507235eed5.png')
                    }
                  />
                  <span className={`${styles['text-group_10']}`}>上海申美信息科技有限公司</span>
                </div>
                <span className={`${styles['paragraph_4']}`}>
                  地址：上海市普陀区大渡河路388弄5号楼406室
                  <br />
                  电话：13391356946
                  <br />
                  email：surmeta&#64;surmeta.com
                </span>
              </div>
            </div>
            <div className={`${styles['box_14']} flex-row justify-between`}>
              <div className={`${styles['image-text_8']} flex-col justify-between`}>
                <div className={`${styles['block_27']} flex-col`} />
                <span className={`${styles['text-group_11']}`}>官方微信号</span>
              </div>
              <div className={`${styles['image-text_9']} flex-col justify-between`}>
                <div className={`${styles['group_2']} flex-col`} />
                <span className={`${styles['text-group_12']}`}>抖音视频号</span>
              </div>
            </div>
          </div>
        </div>
        <div className={`${styles['block_28']} flex-col`}>
          <div className={`${styles['text-wrapper_50']}`}>
            <span className={`${styles['paragraph_5']}`}>
              沪ICP证0876125号&nbsp;&nbsp;&nbsp;沪ICP备987788号&nbsp;
              <br />
              copyright©
            </span>
            <span className={`${styles['text_84']}`}>SurMeta&nbsp;</span>
            <span className={`${styles['text_85']}`}>法律声明</span>
          </div>
        </div>
      </div>
    );
  }
}
export default DDS_lanhupage_0;
