'use strict';

import React, { Component } from 'react';
import {Nav} from '../component/nav';
import {Footer} from '../component/footer';
import {ServerContent} from './server.jsx';
import {SolutionContent} from './solution';
import {MapWorld} from './map'
import {Cst} from './cst'

import './index.css';


class index extends Component {

  state = {mapState:'zg'}

  chgMap = () =>{
    if(this.state.mapState == 'zg'){
      this.setState({mapState:'sj'});
    }else{
      this.setState({mapState:'zg'});
    }
  }

  render() {
    return (
      <div className='page-main'>
        <Nav curr="首页"></Nav>
        <img 
            className="app-index-banner" 
                referrerPolicy="no-referrer" 
                src={
                    require('./img/main-bg.png')
                    }
        ></img>
        <div className='app-index-server'>
            <span className='app-index-server-title'>更专业的服务</span>
            <span className='app-index-server-subtitle'>服务至上，客户为本，交付精准，支撑有力，流程高效</span>
            <ServerContent />
        </div>

        <div className='app-index-solution' >
            <span className='app-index-server-title'>行业解决方案</span>
            <span className='app-index-server-subtitle'>专注细分领域行业解决方案</span>

            <SolutionContent />

            <div className='app-index-solution-more'>
              {/* <span>了解更多</span> */}
            </div>
        </div>

        <div className='app-index-location'>
              <div className='app-index-server-title'>业务交付网络</div>
              <div className='app-index-location-subtitle'>
                <span>
                    公司立足上海、服务全国，为金融、汽车、互联网、通讯、数字科技、制造、零售、房地产、医疗、教育、交通、政务、航空、物流、能源、环保等行业客户，提供数智化产品和科技服务，使客户聚焦自身核心主业，更专注与高效实现商业成功。
                </span>
                
              </div>

              <div className='app-index-location-map'>
                    <MapWorld/>
              </div>

            

        </div>

          <div className='app-index-cst-bg'>
                <div className='app-index-server-title'>服务客户</div>
                <div className='app-index-location-subtitle'>
                  <span>
                      申美信息拥有一批具有自主知识产权的核心开发成果，技术力量雄厚，先后获得双软、CMMI5等多项权威资质认证，并与平安集团、腾讯、OPPO、百度、顺丰集团等数百家知名金融公司、世界500强、上市公司，建立了长期合作伙伴关系。
                  </span>
                </div>

                <Cst/>

              </div>

        <Footer/>
 
       </div>
    )
  }
}
export default index;
