import React from "react";
import { Link  } from "react-router-dom";


export const Nav = (props) =>{

    const navlist =[
        {name:'首页',
        url:'/'
        },
        {name:'公司简介',
        url:'/about'
        },
        {name:'主营业务',
        url:'/business'
        },
        {name:'产品方案',
        url:'/solution'
        },
        {name:'合作伙伴',
        url:'/partner'
        },
        // {name:'营业网点',
        // url:'/location'
        // },
        // {name:'人才招聘',
        // url:'/recruit'
        // },
    ];
    


    

    return (<div className="app-header">
                <img 
                className="app-log-img" 
                referrerPolicy="no-referrer" 
                src={
                    require('./img/logo.png')
                    }
                ></img>

            <div className="app-nav-container">  
                <ul>
                    {navlist.map((d,i)=>{
                        return  (
                        <li key={i}  data-isactive={props.curr==d.name} >
                            <Link to={d.url}> {d.name} </Link>
                            </li>
                            );
                    })}
                </ul>
            </div>

    </div>)
}