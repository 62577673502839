import React from "react";

export const SolutionContent = () =>{

    return (<div style={{marginTop:'20px'}}>

        <div className="app-index-solution-container">
            <div className="app-index-solution-item"  data-type='jr'>
                <div className="app-index-solution-item-title">金融</div>
            </div>

             <div className="app-index-solution-item"  data-type='qc'>
                <div className="app-index-solution-item-title">汽车</div>
            </div>

            <div className="app-index-solution-item"  data-type='hlw'>
                <div className="app-index-solution-item-title">互联网</div>
            </div>
        </div>


        <div className="app-index-solution-container">
            <div className="app-index-solution-item"  data-type='tx'>
                <div className="app-index-solution-item-title">通讯</div>
            </div>

             <div className="app-index-solution-item"  data-type='zz'>
                <div className="app-index-solution-item-title">制造</div>
            </div>

            <div className="app-index-solution-item"  data-type='fdc'>
                <div className="app-index-solution-item-title">房地产</div>
            </div>
        </div>

        
        <div className="app-index-solution-container">
            <div className="app-index-solution-item"  data-type='yl'>
                <div className="app-index-solution-item-title">医疗</div>
            </div>

             <div className="app-index-solution-item"  data-type='jt'>
                <div className="app-index-solution-item-title">交通</div>
            </div>

            <div className="app-index-solution-item"  data-type='ny'>
                <div className="app-index-solution-item-title">能源</div>
            </div>
        </div>
          

    </div>)
}