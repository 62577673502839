import React from "react";

export const Cst = () =>{

    return ( <div>
    
               <div className='app-index-cst-totle'>
                    <div className="app-index-cst-totle-item-blank"></div>
                  <div className="app-index-cst-totle-item">
                    <div className="app-index-cst-totle-item-a">200+</div>
                    <div className="app-index-cst-totle-item-b">服务客户</div>
                  </div>

                  <div className="app-index-cst-totle-item">
                    <div className="app-index-cst-totle-item-a">6+</div>
                    <div className="app-index-cst-totle-item-b">核心技术能力</div>
                  </div>

                   <div className="app-index-cst-totle-item">
                    <div className="app-index-cst-totle-item-a">500+</div>
                    <div className="app-index-cst-totle-item-b">落地案例</div>
                  </div>

                  <div className="app-index-cst-totle-item">
                    <div className="app-index-cst-totle-item-a">16+</div>
                    <div className="app-index-cst-totle-item-b">技术领域</div>
                  </div>
                  <div className="app-index-cst-totle-item-blank"></div>
                  
                 </div> 


                 <div className='app-index-cst-content' style={{marginTop:20}}>
                    <div className="app-index-cst-content-item">
                        <img
                        src={
                            require('./img/cst1.png')
                            }
                        ></img>
                    </div>

                     <div className="app-index-cst-content-item">
                        <img
                        src={
                            require('./img/cst2.png')
                            }
                        ></img>
                    </div>

                     <div className="app-index-cst-content-item">
                        <img
                        src={
                            require('./img/cst3.png')
                            }
                        ></img>
                    </div>

                     <div className="app-index-cst-content-item">
                        <img
                        src={
                            require('./img/cst4.png')
                            }
                        ></img>
                    </div>
                </div>  


                 <div className='app-index-cst-content' style={{marginTop:10}}>
                    <div className="app-index-cst-content-item">
                        <img
                        src={
                            require('./img/cst5.png')
                            }
                        ></img>
                    </div>

                     <div className="app-index-cst-content-item">
                        <img
                        src={
                            require('./img/cst5.png')
                            }
                        ></img>
                    </div>

                     <div className="app-index-cst-content-item">
                        <img
                        src={
                            require('./img/cst7.png')
                            }
                        ></img>
                    </div>

                     <div className="app-index-cst-content-item">
                        <img
                        src={
                            require('./img/cst8.png')
                            }
                        ></img>
                    </div>
                </div>  


                 <div className='app-index-cst-content' style={{marginTop:10}}>
                    <div className="app-index-cst-content-item">
                        <img
                        src={
                            require('./img/cst9.png')
                            }
                        ></img>
                    </div>

                     <div className="app-index-cst-content-item">
                        <img
                        src={
                            require('./img/cst10.png')
                            }
                        ></img>
                    </div>

                     <div className="app-index-cst-content-item">
                        <img
                        src={
                            require('./img/cst11.png')
                            }
                        ></img>
                    </div>

                     <div className="app-index-cst-content-item">
                        <img
                        src={
                            require('./img/cst12.png')
                            }
                        ></img>
                    </div>
                </div> 

                <div className='app-index-cst-content' style={{marginTop:10}}>
                    <div className="app-index-cst-content-item">
                        <img
                        src={
                            require('./img/cst13.png')
                            }
                        ></img>
                    </div>

                     <div className="app-index-cst-content-item">
                        <img
                        src={
                            require('./img/cst14.png')
                            }
                        ></img>
                    </div>

                     <div className="app-index-cst-content-item">
                        <img
                        src={
                            require('./img/cst15.png')
                            }
                        ></img>
                    </div>

                     <div className="app-index-cst-content-item">
                        <img
                        src={
                            require('./img/cst16.png')
                            }
                        ></img>
                    </div>
                </div>  

                <div className="app-index-cst-content" style={{marginTop:20}}>
                    <div className="app-index-cst-evaluate" style={{marginRight:10}}>
                        <div className="app-index-cst-evaluate-dec">申美信息拥有较强的开发能力和丰富经验的专业化队伍。和他们合作的这几年，给公司节约了开发成本，交付迅速高效。是值得信赖的伙伴。
                        </div>
                        <div className="app-index-cst-evaluate-cst">——金融公司</div>
                    </div>
                    <div className="app-index-cst-evaluate">
                        <div className="app-index-cst-evaluate-dec">集团是很早就与申美信息合作的企业，多年来的合作成果颇丰，未来还将继续深入合作。
                        </div>
                        <div className="app-index-cst-evaluate-cst">—— 集团公司</div>
                    </div>
                </div> 

            </div>)
}

