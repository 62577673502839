'use strict';

import React, { Component } from 'react';
import { Switch, NavBar, Checkbox, Radio, Input, Tabs, TabBar, Badge } from 'antd-mobile';

import styles from './assets/index.module.css';

const print = function (value) {};
class DDS_lanhupage_0 extends Component {
  state = {};
  setValue(val) {}
  render() {
    return (
      <div className={`${styles.page} flex-col`}>
        <div className={`${styles['box_1']} flex-col`}>
          <div className={`${styles['box_2']} flex-row`}>
            <img
              className={`${styles['image_1']}`}
              src={
                require('./assets/img/SketchPng9f06d0db570db41de5c026ea08d5b9fd623f507cc4e13b72b7d9ec43ff08d10c.png')
              }
            />
            <span className={`${styles['text_1']}`}>首页</span>
            <span className={`${styles['text_2']}`}>公司简介</span>
            <span className={`${styles['text_3']}`}>主营业务</span>
            <span className={`${styles['text_4']}`}>产品方案</span>
            <span className={`${styles['text_5']}`}>合作伙伴</span>
            <span className={`${styles['text_6']}`}>营业网点</span>
            <span className={`${styles['text_7']}`}>&nbsp;人才招聘</span>
            <span className={`${styles['text_8']}`}>中/</span>
            <span className={`${styles['text_9']}`}>En</span>
            <img
              className={`${styles['label_1']}`}
              src={
                require('./assets/img/SketchPng891cf14de262b84ebfcf52ec76b81e4dfc293a2ca6a043b18cda43ce54425544.png')
              }
            />
            <span className={`${styles['text_10']}`}>400-880-5656</span>
          </div>
          <img
            className={`${styles['image_2']}`}
            src={
              require('./assets/img/SketchPng868a3f22ebab39a4f6c9981a5629726981e383e4cf120801e6039676483d8c56.png')
            }
          />
        </div>
        <div className={`${styles['box_3']} flex-col`}>
          <div className={`${styles['text-group_1']} flex-row`}>
            <span className={`${styles['text_11']}`}>更专业的服务</span>
            <span className={`${styles['text_12']}`}>服务之上，客户为本，交付精准，支撑有力，流程高效</span>
          </div>
          <div className={`${styles['group_1']} flex-row`}>
            <div className={`${styles['text-wrapper_1']} flex-col`}>
              <span className={`${styles['text_13']}`}>KPO</span>
            </div>
            <div className={`${styles['text-wrapper_2']} flex-col`}>
              <span className={`${styles['text_14']}`}>ITO</span>
            </div>
            <div className={`${styles['text-wrapper_3']} flex-col`}>
              <span className={`${styles['text_15']}`}>ITEB</span>
            </div>
          </div>
          <div className={`${styles['group_2']} flex-row`}>
            <div className={`${styles['text-group_2']} flex-col justify-between`}>
              <span className={`${styles['text_16']}`}>知识流程外包服务(KPO)</span>
              <span className={`${styles['text_17']}`}>海量客户案例总结沉淀，向客户提供决策依据和优化方案。</span>
            </div>
            <div className={`${styles['text-wrapper_4']} flex-col justify-between`}>
              <span className={`${styles['text_18']}`}>信息技术外包服务(ITO)</span>
              <span className={`${styles['text_19']}`}>以客户为中心的全链系统支撑</span>
            </div>
            <div className={`${styles['text-group_3']} flex-col justify-between`}>
              <span className={`${styles['text_20']}`}>信息技术生态业务(ITEB)</span>
              <span className={`${styles['text_21']}`}>企业定制化开发、行业解决方案、系统集成与部署，精准高效</span>
            </div>
          </div>
          <div className={`${styles['group_3']} flex-row justify-between`}>
            <div className={`${styles['block_1']} flex-row`}>
              <div className={`${styles['image-text_1']} flex-row justify-between`}>
                <span className={`${styles['text-group_4']}`}>了解详情</span>
                <img
                  className={`${styles['thumbnail_1']}`}
                  src={
                    require('./assets/img/SketchPnga5e41a414111d264a82e6cd746a628edf276d84e095508c09d222915dc9f40ae.png')
                  }
                />
              </div>
            </div>
            <div className={`${styles['block_2']} flex-row`}>
              <div className={`${styles['image-text_2']} flex-row justify-between`}>
                <span className={`${styles['text-group_5']}`}>了解详情</span>
                <img
                  className={`${styles['thumbnail_2']}`}
                  src={
                    require('./assets/img/SketchPnga5e41a414111d264a82e6cd746a628edf276d84e095508c09d222915dc9f40ae.png')
                  }
                />
              </div>
            </div>
            <div className={`${styles['block_3']} flex-row`}>
              <div className={`${styles['image-text_3']} flex-row justify-between`}>
                <span className={`${styles['text-group_6']}`}>了解详情</span>
                <img
                  className={`${styles['thumbnail_3']}`}
                  src={
                    require('./assets/img/SketchPnga5e41a414111d264a82e6cd746a628edf276d84e095508c09d222915dc9f40ae.png')
                  }
                />
              </div>
            </div>
          </div>
          <div className={`${styles['group_4']} flex-row`}>
            <div className={`${styles['text-wrapper_5']} flex-col`}>
              <span className={`${styles['text_22']}`}>BPO</span>
            </div>
            <div className={`${styles['text-wrapper_6']} flex-col`}>
              <span className={`${styles['text_23']}`}>HRO</span>
            </div>
            <div className={`${styles['text-wrapper_7']} flex-col`}>
              <span className={`${styles['text_24']}`}>ECS</span>
            </div>
            <div className={`${styles['box_4']} flex-col justify-between`}>
              <div className={`${styles['text-wrapper_8']} flex-col`}>
                <span className={`${styles['text_25']}`}>400-880-5656</span>
              </div>
              <div className={`${styles['image-wrapper_1']} flex-col`}>
                <img
                  className={`${styles['image_3']}`}
                  src={
                    require('./assets/img/SketchPng984356296191090c29f6712910f49b172ab09dfb185997ac01d0424df71926ca.png')
                  }
                />
              </div>
            </div>
            <div className={`${styles['box_5']} flex-col`}>
              <div className={`${styles['image-wrapper_2']} flex-col`}>
                <img
                  className={`${styles['label_2']}`}
                  src={
                    require('./assets/img/SketchPngd59fa8a25cef6666e59a2536ef81b00bbe00c4aa7d49b98b074af3e577e91a79.png')
                  }
                />
              </div>
              <div className={`${styles['image-wrapper_3']} flex-col`}>
                <img
                  className={`${styles['label_3']}`}
                  src={
                    require('./assets/img/SketchPng4d494af1388871e040ee39fde51f78fb15f457136d7d23857af6939a28b366ca.png')
                  }
                />
              </div>
              <div className={`${styles['group_5']} flex-col`}>
                <div className={`${styles['image-wrapper_4']} flex-col`}>
                  <img
                    className={`${styles['image_4']}`}
                    src={
                      require('./assets/img/SketchPngeb461a106bbf020de165a9d61b324e6b6b9faf9cacb53fbd537309ffe6debfa7.png')
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={`${styles['list_1']} flex-row`}>
            <div className={`${styles['text-group_7-0']} flex-col justify-between`}>
              <span className={`${styles['text_26-0']}`}>商务流程外包服务(BPO）</span>
              <span className={`${styles['text_27-0']}`}>
                致力企业全流程业务外包，提供于多元化端到端业务流程外包解决方案
                <br />
                <br />
              </span>
            </div>
            <div className={`${styles['text-group_7-1']} flex-col justify-between`}>
              <span className={`${styles['text_26-1']}`}>人力资源外包服务(HRO)</span>
              <span className={`${styles['text_27-1']}`}>
                细致人才识别与标签&nbsp;,全面的需求理解与分析,&nbsp;海量的历史数据与经验,精准的匹配与持续进化
                <br />
                <br />
              </span>
            </div>
            <div className={`${styles['text-group_7-2']} flex-col justify-between`}>
              <span className={`${styles['text_26-2']}`}>企业综合服务(ECS)</span>
              <span className={`${styles['text_27-2']}`}>
                帮客户构筑刚性业务壁垒，提升市场竞争力，制胜未来。
                <br />
                <br />
              </span>
            </div>
          </div>
          <div className={`${styles['group_6']} flex-row justify-between`}>
            <div className={`${styles['block_4']} flex-row`}>
              <div className={`${styles['image-text_4']} flex-row justify-between`}>
                <span className={`${styles['text-group_8']}`}>了解详情</span>
                <img
                  className={`${styles['thumbnail_4']}`}
                  src={
                    require('./assets/img/SketchPnga5e41a414111d264a82e6cd746a628edf276d84e095508c09d222915dc9f40ae.png')
                  }
                />
              </div>
            </div>
            <div className={`${styles['block_5']} flex-row`}>
              <div className={`${styles['image-text_5']} flex-row justify-between`}>
                <span className={`${styles['text-group_9']}`}>了解详情</span>
                <img
                  className={`${styles['thumbnail_5']}`}
                  src={
                    require('./assets/img/SketchPnga5e41a414111d264a82e6cd746a628edf276d84e095508c09d222915dc9f40ae.png')
                  }
                />
              </div>
            </div>
            <div className={`${styles['block_6']} flex-row`}>
              <div className={`${styles['image-text_6']} flex-row justify-between`}>
                <span className={`${styles['text-group_10']}`}>了解详情</span>
                <img
                  className={`${styles['thumbnail_6']}`}
                  src={
                    require('./assets/img/SketchPnga5e41a414111d264a82e6cd746a628edf276d84e095508c09d222915dc9f40ae.png')
                  }
                />
              </div>
            </div>
          </div>
          <div className={`${styles['group_7']} flex-col`}>
            <div className={`${styles['text-group_11']} flex-col justify-between`}>
              <span className={`${styles['text_28']}`}>行业解决方案</span>
              <span className={`${styles['text_29']}`}>专注细分领域行业解决方案</span>
            </div>
            <div className={`${styles['grid_1']} flex-row`}>
              <div className={`${styles['list-items_1-0']} flex-col`}>
                <div className={`${styles['section_1-0']} flex-col`}>
                  <img
                    className={`${styles['label_4-0']}`}
                    src={
                      require('./assets/img/SketchPngae7223e2fcdc689f28bb5aed5e0a20f978faf8e988be107f136f1e75bed66fb3.png')
                    }
                  />
                  <span className={`${styles['text_30-0']}`}>金融</span>
                </div>
              </div>
              <div className={`${styles['list-items_1-1']} flex-col`}>
                <div className={`${styles['section_1-1']} flex-col`}>
                  <img
                    className={`${styles['label_4-1']}`}
                    src={
                      require('./assets/img/SketchPng6d9275c4006d1ee780a7049f85ae1e7abc3094401b6133e70225eb15f088e059.png')
                    }
                  />
                  <span className={`${styles['text_30-1']}`}>汽车</span>
                </div>
              </div>
              <div className={`${styles['list-items_1-2']} flex-col`}>
                <div className={`${styles['section_1-2']} flex-col`}>
                  <img
                    className={`${styles['label_4-2']}`}
                    src={
                      require('./assets/img/SketchPngd11eac2325caa7a5519c6f8d845582cc2f01a55e0202ba76a78a6036986dc312.png')
                    }
                  />
                  <span className={`${styles['text_30-2']}`}>互联网</span>
                </div>
              </div>
              <div className={`${styles['list-items_1-3']} flex-col`}>
                <div className={`${styles['section_1-3']} flex-col`}>
                  <img
                    className={`${styles['label_4-3']}`}
                    src={
                      require('./assets/img/SketchPng4b688a8d51504d7326732158d729df138894be1d4965fd57a8901fcc87000485.png')
                    }
                  />
                  <span className={`${styles['text_30-3']}`}>通讯</span>
                </div>
              </div>
              <div className={`${styles['list-items_1-4']} flex-col`}>
                <div className={`${styles['section_1-4']} flex-col`}>
                  <img
                    className={`${styles['label_4-4']}`}
                    src={
                      require('./assets/img/SketchPngceeb0565fecab6ee2f7c8c4a9419c065d2eda7620696798a19480a166101265e.png')
                    }
                  />
                  <span className={`${styles['text_30-4']}`}>制造</span>
                </div>
              </div>
              <div className={`${styles['list-items_1-5']} flex-col`}>
                <div className={`${styles['section_1-5']} flex-col`}>
                  <img
                    className={`${styles['label_4-5']}`}
                    src={
                      require('./assets/img/SketchPngecd48fe81b9608f8bd08deb05c51534783cb6a1e0d5a60e7690a460ff20a7e3d.png')
                    }
                  />
                  <span className={`${styles['text_30-5']}`}>房地产</span>
                </div>
              </div>
              <div className={`${styles['list-items_1-6']} flex-col`}>
                <div className={`${styles['section_1-6']} flex-col`}>
                  <img
                    className={`${styles['label_4-6']}`}
                    src={
                      require('./assets/img/SketchPng500bf04e67ead8ef7204b778b22ae1d793f2d0e94605be5f9573ea121adbad10.png')
                    }
                  />
                  <span className={`${styles['text_30-6']}`}>医疗</span>
                </div>
              </div>
              <div className={`${styles['list-items_1-7']} flex-col`}>
                <div className={`${styles['section_1-7']} flex-col`}>
                  <img
                    className={`${styles['label_4-7']}`}
                    src={
                      require('./assets/img/SketchPng472f4e3b4e905e5592de1db4c5447675a61e0e9bb116928202d3f0bfac7124af.png')
                    }
                  />
                  <span className={`${styles['text_30-7']}`}>交通</span>
                </div>
              </div>
              <div className={`${styles['list-items_1-8']} flex-col`}>
                <div className={`${styles['section_1-8']} flex-col`}>
                  <img
                    className={`${styles['label_4-8']}`}
                    src={
                      require('./assets/img/SketchPng0a49d2611bad9aee83a4bbe5e3840cf3b56234379720133a783bdf5688f30336.png')
                    }
                  />
                  <span className={`${styles['text_30-8']}`}>能源</span>
                </div>
              </div>
            </div>
            <span className={`${styles['text_31']}`}>了解更多</span>
            <div className={`${styles['block_7']} flex-col`} />
          </div>
          <div className={`${styles['text-group_12']} flex-col justify-between`}>
            <span className={`${styles['text_32']}`}>业务交付网络</span>
            <span className={`${styles['paragraph_1']}`}>
              公司立足上海、服务全国，为金融、汽车、互联网、通讯、数字科技、制造、零售、房地产、医疗、教育、交通、政务、航空、物流、能源、环保等行业客户，提供数智化产品和科技服务，使客户聚焦自身核心主业，更专注与高效实现商业成功。
              <br />
              <br />
            </span>
          </div>
          <div className={`${styles['group_8']} flex-row justify-between`}>
            <div className={`${styles['section_2']} flex-row`}>
              <div className={`${styles['box_6']} flex-col`}>
                <div className={`${styles['block_8']} flex-row`}>
                  <div className={`${styles['section_3']} flex-col`}>
                    <div className={`${styles['group_9']} flex-col`} />
                  </div>
                </div>
                <div className={`${styles['block_9']} flex-row justify-between`}>
                  <div className={`${styles['box_7']} flex-col`}>
                    <div className={`${styles['box_8']} flex-col`} />
                  </div>
                  <span className={`${styles['text_33']}`}>厦门</span>
                </div>
                <div className={`${styles['block_10']} flex-row`}>
                  <div className={`${styles['group_10']} flex-col`} />
                </div>
                <div className={`${styles['text-wrapper_9']} flex-row`}>
                  <span className={`${styles['text_34']}`}>深圳</span>
                </div>
                <div className={`${styles['block_11']} flex-row`}>
                  <div className={`${styles['box_9']} flex-col`} />
                </div>
                <div className={`${styles['block_12']} flex-row`}>
                  <div className={`${styles['group_11']} flex-col`} />
                </div>
                <div className={`${styles['text-wrapper_10']} flex-row`}>
                  <span className={`${styles['text_35']}`}>北京</span>
                </div>
                <div className={`${styles['block_13']} flex-row justify-between`}>
                  <span className={`${styles['text_36']}`}>西安</span>
                  <div className={`${styles['box_10']} flex-col`}>
                    <div className={`${styles['box_11']} flex-col`} />
                  </div>
                </div>
                <div className={`${styles['block_14']} flex-row`}>
                  <div className={`${styles['group_12']} flex-col`}>
                    <div className={`${styles['box_12']} flex-col`}>
                      <div className={`${styles['box_13']} flex-col`} />
                    </div>
                    <span className={`${styles['text_37']}`}>成都</span>
                  </div>
                  <span className={`${styles['text_38']}`}>长沙</span>
                  <div className={`${styles['group_13']} flex-col justify-between`}>
                    <span className={`${styles['text_39']}`}>上海</span>
                    <div className={`${styles['group_14']} flex-col`} />
                  </div>
                  <div className={`${styles['group_15']} flex-col justify-between`}>
                    <span className={`${styles['text_40']}`}>杭州</span>
                    <div className={`${styles['group_16']} flex-col`} />
                  </div>
                </div>
                <div className={`${styles['block_15']} flex-col`} />
                <div className={`${styles['block_16']} flex-col`} />
                <div className={`${styles['block_17']} flex-col`} />
                <div className={`${styles['block_18']} flex-col`} />
                <div className={`${styles['block_19']} flex-col`} />
                <span className={`${styles['text_41']}`}>香港</span>
              </div>
              <img
                className={`${styles['image_5']}`}
                src={
                  require('./assets/img/SketchPng78ddf0f42de527247ac7b4261e92fdddcbcbd6d21ed4dc10024c7d88f659c12a.png')
                }
              />
            </div>
            <div className={`${styles['section_4']} flex-col`}>
              <span className={`${styles['text_42']}`}>世界</span>
              <img
                className={`${styles['label_5']}`}
                src={
                  require('./assets/img/SketchPngd4cae541193bd18f8807c20636db5e5f102473d4775a2c5fcfd7cbe315c01db4.png')
                }
              />
            </div>
          </div>
          <div className={`${styles['group_17']} flex-row`}>
            <div className={`${styles['group_18']} flex-row`}>
              <div className={`${styles['image-text_7']} flex-row justify-between`}>
                <img
                  className={`${styles['image_6']}`}
                  src={
                    require('./assets/img/SketchPng9d58d8f429f1007c2f529b42b5dfe3e6912f568e24e15c3dd4dcc8536b6ca192.png')
                  }
                />
                <span className={`${styles['text-group_13']}`}>
                  申美信息拥有较强的开发能力和丰富经验的专业化队伍。和他们合作的这几年，给公司节约了开发成本，交付迅速高效。是值得信赖的伙伴。
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;——金融公司
                </span>
              </div>
            </div>
            <div className={`${styles['image-wrapper_5']} flex-col`}>
              <img
                className={`${styles['image_7']}`}
                src={
                  require('./assets/img/SketchPngf5e617148874b38352b1ec8728c2b5a46ce317daefa0e2143a3cb61797621395.png')
                }
              />
            </div>
            <div className={`${styles['image-wrapper_6']} flex-col`}>
              <img
                className={`${styles['image_8']}`}
                src={
                  require('./assets/img/SketchPngf92d0fac75ce3b56e4e5612194e3da583f3adade0ab100b9bf641e28c8070356.png')
                }
              />
            </div>
            <div className={`${styles['image-wrapper_7']} flex-col`}>
              <img
                className={`${styles['image_9']}`}
                src={
                  require('./assets/img/SketchPng7723d63f9227ebfff9411b388f0ed5d2f514642b2361a513db49dd0cd908877d.png')
                }
              />
            </div>
            <div className={`${styles['image-wrapper_8']} flex-col`}>
              <img
                className={`${styles['image_10']}`}
                src={
                  require('./assets/img/SketchPng51ac87840ffe5aaff9438289da7e8fca8def634bb25da8456f96f2b288e69c7b.png')
                }
              />
            </div>
            <div className={`${styles['image-wrapper_9']} flex-col`}>
              <img
                className={`${styles['image_11']}`}
                src={
                  require('./assets/img/SketchPng8f3e87cfaca69aea4443c3d9eb95acf9e449b7ede6bc98b4ecf29af98bff7bd9.png')
                }
              />
            </div>
            <div className={`${styles['image-wrapper_10']} flex-col`}>
              <img
                className={`${styles['image_12']}`}
                src={
                  require('./assets/img/SketchPngebbda00577c5ce455ec0b6b9946a6e06ed68c2e0b60dc14b2b298bbee3c0e1df.png')
                }
              />
            </div>
            <div className={`${styles['image-wrapper_11']} flex-col`}>
              <img
                className={`${styles['image_13']}`}
                src={
                  require('./assets/img/SketchPng5083bae8e039e4a2adaf242fabc0927f2e0e270e00c86e1430b441d0c44afdae.png')
                }
              />
            </div>
            <div className={`${styles['image-wrapper_12']} flex-col`}>
              <img
                className={`${styles['image_14']}`}
                src={
                  require('./assets/img/SketchPnge22bc45fc383cc3b8ea59b071ad87e2527dc457d27fec29c8037eadf69ce9a7f.png')
                }
              />
            </div>
            <span className={`${styles['text_43']}`}>服务客户</span>
            <div className={`${styles['image-wrapper_13']} flex-col`}>
              <img
                className={`${styles['image_15']}`}
                src={
                  require('./assets/img/SketchPngafc1064ec82db03027b451e9738304eee872028564ecdf73bfaded34efb66371.png')
                }
              />
            </div>
            <div className={`${styles['image-wrapper_14']} flex-col`}>
              <img
                className={`${styles['image_16']}`}
                src={
                  require('./assets/img/SketchPnge9584efa477c9f3f57e0fcfb271aa9e81d106384d9af3729ee9701d32942396d.png')
                }
              />
            </div>
            <div className={`${styles['image-wrapper_15']} flex-col`}>
              <img
                className={`${styles['image_17']}`}
                src={
                  require('./assets/img/SketchPng950d4c21a78cbbea30958c61ea90597e73fe1f0672e4326ca6cd85fa02f4f37d.png')
                }
              />
            </div>
            <div className={`${styles['image-wrapper_16']} flex-col`}>
              <img
                className={`${styles['image_18']}`}
                src={
                  require('./assets/img/SketchPng2a9d0de64f48930d8ae00cec4de43bfb9917f010ddda89895b8f1982653bcae8.png')
                }
              />
            </div>
            <img
              className={`${styles['image_19']}`}
              src={
                require('./assets/img/SketchPng1f9790d34500270d46bd56e820bcec1169e2dd41fd9b3c50e8f311cd214158a5.png')
              }
            />
            <div className={`${styles['group_19']} flex-col`}>
              <div className={`${styles['box_14']} flex-col`}>
                <span className={`${styles['paragraph_2']}`}>
                  丰富的应用场景和行业解决方案，为企业赋能、让生产
                  <br />
                  高效、降本增效
                </span>
                <div className={`${styles['image-text_8']} flex-row justify-between`}>
                  <div className={`${styles['box_15']} flex-row`}>
                    <div className={`${styles['image-text_9']} flex-row justify-between`}>
                      <span className={`${styles['text-group_14']}`}>电话咨询</span>
                      <img
                        className={`${styles['thumbnail_7']}`}
                        src={
                          require('./assets/img/SketchPng35c0f2e8340648d41a261fbf3a14647c23e57cd7108e01120254985b00721c67.png')
                        }
                      />
                    </div>
                    <div className={`${styles['image-text_10']} flex-row justify-between`}>
                      <span className={`${styles['text-group_14']}`}>电话咨询</span>
                      <img
                        className={`${styles['thumbnail_7']}`}
                        src={
                          require('./assets/img/SketchPng35c0f2e8340648d41a261fbf3a14647c23e57cd7108e01120254985b00721c67.png')
                        }
                      />
                    </div>
                  </div>
                  <span className={`${styles['text-group_15']}`}>400-880-5656</span>
                </div>
                <img
                  className={`${styles['image_20']}`}
                  src={
                    require('./assets/img/SketchPng0ee43f01bd13f6ba21297c72b1908216c8d8f3811421a22e7f0638fc11277f0e.png')
                  }
                />
              </div>
            </div>
            <div className={`${styles['group_20']} flex-row`}>
              <div className={`${styles['text-group_16']} flex-col justify-between`}>
                <span className={`${styles['text_44']}`}>200+</span>
                <span className={`${styles['text_45']}`}>服务客户</span>
              </div>
              <div className={`${styles['text-group_17']} flex-col justify-between`}>
                <span className={`${styles['text_46']}`}>6+</span>
                <span className={`${styles['text_47']}`}>核心技术能力</span>
              </div>
              <div className={`${styles['text-group_18']} flex-col justify-between`}>
                <span className={`${styles['text_48']}`}>500+</span>
                <span className={`${styles['text_49']}`}>落地案例</span>
              </div>
              <div className={`${styles['text-group_19']} flex-col justify-between`}>
                <span className={`${styles['text_50']}`}>16+</span>
                <span className={`${styles['text_51']}`}>技术领域</span>
              </div>
            </div>
            <span className={`${styles['text_52']}`}>
              申美信息拥有一批具有自主知识产权的核心开发成果，技术力量雄厚，先后获得双软、CMMI5等多项权威资质认证，并与平安集团、腾讯、OPPO、百度、顺丰集团等数百家知名金融公司、世界500强、上市公司，建立了长期合作伙伴关系。
            </span>
            <div className={`${styles['group_21']} flex-row`}>
              <div className={`${styles['image-text_11']} flex-row justify-between`}>
                <img
                  className={`${styles['image_21']}`}
                  src={
                    require('./assets/img/SketchPng9d58d8f429f1007c2f529b42b5dfe3e6912f568e24e15c3dd4dcc8536b6ca192.png')
                  }
                />
                <span className={`${styles['text-group_20']}`}>
                  集团是很早就与申美信息合作的企业，多年来的合作成果颇丰，未来还将继续深入合作。
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;——&nbsp;集团公司
                </span>
              </div>
            </div>
            <div className={`${styles['image-wrapper_17']} flex-col`}>
              <img
                className={`${styles['label_6']}`}
                src={
                  require('./assets/img/SketchPng1f85afdf0b802e5ce41c23fcd4b6f06d29a34be7bb4aeb83fd0db3bdfa4c61d8.png')
                }
              />
            </div>
            <div className={`${styles['image-wrapper_18']} flex-col`}>
              <img
                className={`${styles['image_22']}`}
                src={
                  require('./assets/img/SketchPng795f2bb5dfb5a9c32f3d4c0205aacf68ab1b0d5ec92d93a238b012d3d66bc1c4.png')
                }
              />
            </div>
            <div className={`${styles['image-wrapper_19']} flex-col`}>
              <img
                className={`${styles['label_7']}`}
                src={
                  require('./assets/img/SketchPng8c0ca8e33706c2b67778fbedb089f2c9285f3d12f9bbb7cad581e4d0e07d1e63.png')
                }
              />
            </div>
            <div className={`${styles['image-wrapper_20']} flex-col`}>
              <img
                className={`${styles['image_23']}`}
                src={
                  require('./assets/img/SketchPnge70f7f07c9064d123aec222b73621596618bb8fc7e118a2c07dc9df716d8437c.png')
                }
              />
            </div>
          </div>
          <div className={`${styles['group_22']} flex-col`}>
            <div className={`${styles['box_16']} flex-row`}>
              <div className={`${styles['group_23']} flex-col justify-between`}>
                <span className={`${styles['text_53']}`}>IT综合服务</span>
                <div className={`${styles['text-group_21']} flex-col justify-between`}>
                  <span className={`${styles['text_54']}`}>ITO人力</span>
                  <span className={`${styles['text_55']}`}>ITO项目</span>
                  <span className={`${styles['text_56']}`}>ITO测试</span>
                </div>
              </div>
              <div className={`${styles['group_24']} flex-col justify-between`}>
                <span className={`${styles['text_57']}`}>企业服务</span>
                <div className={`${styles['text-group_22']} flex-col justify-between`}>
                  <span className={`${styles['text_58']}`}>BPO服务</span>
                  <span className={`${styles['text_59']}`}>NDC&amp;ODC</span>
                  <span className={`${styles['text_60']}`}>知识产权服务</span>
                </div>
              </div>
              <div className={`${styles['group_25']} flex-col justify-between`}>
                <span className={`${styles['text_61']}`}>关于申美</span>
                <div className={`${styles['text-group_23']} flex-col justify-between`}>
                  <span className={`${styles['text_62']}`}>企业简介</span>
                  <span className={`${styles['text_63']}`}>招聘中心</span>
                  <span className={`${styles['text_64']}`}>合作伙伴</span>
                </div>
              </div>
              <div className={`${styles['group_26']} flex-col justify-between`}>
                <div className={`${styles['image-text_12']} flex-col justify-between`}>
                  <img
                    className={`${styles['image_24']}`}
                    src={
                      require('./assets/img/SketchPngc6cbbd9032162aa64557e58a63aa4ca3f06762f357621999bb52d7507235eed5.png')
                    }
                  />
                  <span className={`${styles['text-group_24']}`}>上海申美信息科技有限公司</span>
                </div>
                <span className={`${styles['paragraph_3']}`}>
                  地址：上海市普陀区大渡河路388弄5号楼406室
                  <br />
                  电话：13391356946
                  <br />
                  email：surmeta&#64;surmeta.com
                </span>
              </div>
            </div>
            <div className={`${styles['box_17']} flex-row justify-between`}>
              <div className={`${styles['image-text_13']} flex-col justify-between`}>
                <div className={`${styles['section_5']} flex-col`} />
                <span className={`${styles['text-group_25']}`}>官方微信号</span>
              </div>
              <div className={`${styles['image-text_14']} flex-col justify-between`}>
                <div className={`${styles['box_18']} flex-col`} />
                <span className={`${styles['text-group_26']}`}>抖音视频号</span>
              </div>
            </div>
          </div>
        </div>
        <div className={`${styles['box_19']} flex-col`}>
          <div className={`${styles['text-wrapper_11']}`}>
            <span className={`${styles['paragraph_4']}`}>
              沪ICP证0876125号&nbsp;&nbsp;&nbsp;沪ICP备987788号&nbsp;
              <br />
              copyright©
            </span>
            <span className={`${styles['text_65']}`}>SurMeta&nbsp;</span>
            <span className={`${styles['text_66']}`}>法律声明</span>
          </div>
        </div>
      </div>
    );
  }
}
export default DDS_lanhupage_0;
