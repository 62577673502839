import React from "react";

export const ServerContent = () =>{

    return (<div style={{marginTop:'20px'}}>

        
           <div className="app-index-service-container">
                <div className="app-index-service-item">
                    <img
                        referrerPolicy="no-referrer" 
                        src={
                            require('./img/fw-kpo.png')
                            }
                    >
                    </img>
                    <div className="app-index-service-item-title">
                        知识流程外包服务(KPO)
                    </div>
                    <div className="app-index-service-item-dec">
                        海量客户案例总结沉淀，向客户提供决策依据和优化方案。
                    </div>
                    <div className="app-index-service-item-more">
                        {/* <span>了解详情 →</span>    */}
                    </div>
                </div>

                 <div className="app-index-service-item">
                    <img
                        referrerPolicy="no-referrer" 
                        src={
                            require('./img/fw-ito.png')
                            }
                    >
                    </img>
                    <div className="app-index-service-item-title">
                        信息技术外包服务(ITO)
                    </div>
                    <div className="app-index-service-item-dec">
                        以客户为中心的全链系统支撑。
                    </div>
                    <div className="app-index-service-item-more">
                        {/* <span>了解详情 →</span>    */}
                    </div>
                </div>

                 <div className="app-index-service-item">
                    <img
                        referrerPolicy="no-referrer" 
                        src={
                            require('./img/fw-iteb.png')
                            }
                    >
                    </img>
                    <div className="app-index-service-item-title">
                        信息技术生态业务(ITEB)
                    </div>
                    <div className="app-index-service-item-dec">
                       企业定制化开发、行业解决方案、系统集成与部署，精准高效
                    </div>
                    <div className="app-index-service-item-more">
                        {/* <span>了解详情 →</span>    */}
                    </div>
                </div>

            </div>   


             <div className="app-index-service-container">
                <div className="app-index-service-item">
                    <img
                        referrerPolicy="no-referrer" 
                        src={
                            require('./img/fw-bpo.png')
                            }
                    >
                    </img>
                    <div className="app-index-service-item-title">
                        商务流程外包服务(BPO）
                    </div>
                    <div className="app-index-service-item-dec">
                        致力企业全流程业务外包，提供于多元化端到端业务流程外包解决方案
                    </div>
                    <div className="app-index-service-item-more">
                        {/* <span>了解详情 →</span>    */}
                    </div>
                </div>

                 <div className="app-index-service-item">
                    <img
                        referrerPolicy="no-referrer" 
                        src={
                            require('./img/fw-hro.png')
                            }
                    >
                    </img>
                    <div className="app-index-service-item-title">
                        人力资源外包服务(HRO)
                    </div>
                    <div className="app-index-service-item-dec">
                        细致人才识别与标签 ,全面的需求理解与分析, 海量的历史数据与经验,精准的匹配与持续进化
                    </div>
                    <div className="app-index-service-item-more">
                        {/* <span>了解详情 →</span>    */}
                    </div>
                </div>

                 <div className="app-index-service-item">
                    <img
                        referrerPolicy="no-referrer" 
                        src={
                            require('./img/fw-ecs.png')
                            }
                    >
                    </img>
                    <div className="app-index-service-item-title">
                        企业综合服务(ECS)
                    </div>
                    <div className="app-index-service-item-dec">
                       帮客户构筑刚性业务壁垒，提升市场竞争力，制胜未来。
                    </div>
                    <div className="app-index-service-item-more">
                        {/* <span>了解详情 →</span>    */}
                    </div>
                </div>

            </div>  
          

    </div>)
}